import {FC, ReactNode, useEffect} from "react";
import {ReactMaster} from "../../ReactAPIBridgeUtils";
import {NotificationPrototype} from "./NotificationPrototype";
import styled from "styled-components";
import {DescriptiveTypography} from "../components/typography/DescriptiveTypography";
import {Tag, TagProps} from "../../ardai/components/Tag";
import {AnimatePresence, motion} from "framer-motion";
import {v4} from "uuid";
import {useTriton} from "../TritonHooks";
import {
    BugReportRounded, ClearAllRounded,
    CloseRounded,
    ErrorRounded,
    InfoRounded,
    MoreVertRounded,
    WarningAmberRounded
} from "@mui/icons-material";
import {Triton} from "../Triton";
import ReactJson from "react-json-view";
import NotificationLevel = NotificationPrototype.NotificationLevel;
import {DefaultButton} from "../../ardai/components/DefaultButton";
import {MainTypography} from "../components/typography/MainTypography";
import {ofHex} from "../../base/logic/style/Color";
import {Preprocessor} from "../Preprocessor";
import useNotifications = NotificationPrototype.useNotifications;
import {ButtonGroup} from "../../ardai/components/ButtonGroup";

export const NotificationPrototypeStandalone: FC = props => {
    return (
        <ReactMaster fx={NotificationPrototype.notificationCenterReactBridge} children={
            <NotificationPrototypeStandaloneSub/>
        }/>
    );
}

const StyledNotificationPrototypeStandaloneSub = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 300px;
  // max-height: calc(100% - 2 * 16px);
  height: min-content;
  // overflow-y: hidden;
  z-index: 100;
  margin-left: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

let alreadyHookedIntoConsole = false;

export const NotificationPrototypeStandaloneSub: FC = props => {
    const center = NotificationPrototype.useNotifications();
    const t = useTriton();

    useEffect(() => {
        if (alreadyHookedIntoConsole) return;
        const nativeErrorFn = console.error;
        console.error = (message, optionalParams) => {
            center._send(message);
            nativeErrorFn(message, optionalParams);
        }
        alreadyHookedIntoConsole = true;
    }, []);

    return (
        <StyledNotificationPrototypeStandaloneSub>
            <NotificationPrototypeStandaloneNotificationDisplay/>

            <AnimatePresence initial children={
                center.state.notifications.length > 0 && (
                    <NotificationPrototypeStandaloneToolbar/>
                )
            }/>
        </StyledNotificationPrototypeStandaloneSub>
    );
}

export const NotificationPrototypeStandaloneToolbar: FC = props => {
    const center = useNotifications();
    const t = useTriton();

    return (
        <motion.div
            layout
            initial={{
                y: "100%",
                opacity: 0
            }}
            animate={{
                y: 0,
                opacity: 1,
                transition: {
                    duration: 0.15
                }
            }}
            exit={{
                y: "100%",
                opacity: 0,
                transition: {
                    duration: 0.3
                }
            }}
        >
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: 8
            }}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 4
                }}>
                    <ButtonGroup>
                        <Tag
                            applyActiveScaling
                            tag={"Send object"}
                            onClick={() => {
                                center._send(
                                    "the current uuid is:", v4(),
                                    "and my favourite number is:", 42,
                                    () => console.log("It worked!"), "<- is cool",
                                    function doSomethingCool() {
                                        console.error("This is an error")
                                    }
                                );
                            }}
                        />
                        <Tag
                            applyActiveScaling
                            tag={"Do cool"}
                            onClick={() => {
                                center.appendNotification({
                                    id: v4(),
                                    level: NotificationLevel.WARNING,
                                    title: "Delete",
                                    body: ["Are you sure to delete the whole internet? This action can't be undone."],
                                    customRenderer() {
                                        const mainCol = t.colObj("color_primary");

                                        return (
                                            <div style={{
                                                display: "grid",
                                                gap: 8,
                                                gridTemplateColumns: "repeat(2, 1fr)"
                                            }}>
                                                <Tag
                                                    height={32}
                                                    tag={"Cancel"}
                                                />
                                                <Tag
                                                    height={32}
                                                    tag={"Delete"}
                                                    // mainColor={mainCol}
                                                    // style={{
                                                    //     backgroundColor: mainCol.withAlpha(.1).css(),
                                                    //     color: mainCol.hex,
                                                    //     boxShadow: `0px 0px 0px 1px ${mainCol.hex}`
                                                    // }}
                                                />
                                            </div>
                                        );
                                    }
                                })
                            }}
                        />
                    </ButtonGroup>
                </div>

                <div style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 4
                }}>
                    <ButtonGroup>
                        <Tag
                            applyActiveScaling
                            tag={
                                <ClearAllRounded sx={{
                                    fontSize: 14
                                }}/>
                            }
                            onClick={() => {
                                center.setState(prevState => ({
                                    ...prevState,
                                    notifications: []
                                }))
                            }}
                        />
                    </ButtonGroup>
                </div>
            </div>
        </motion.div>
    );
}

const StyledNotificationPrototypeStandaloneNotificationDisplay = styled.div`
`;

export const NotificationPrototypeStandaloneNotificationDisplay: FC = props => {
    const center = NotificationPrototype.useNotifications();

    return (
        <StyledNotificationPrototypeStandaloneNotificationDisplay children={
            <AnimatePresence initial={false} children={
                center.state.notifications.map(notification => (
                    <NotificationDisplay
                        key={notification.id}
                        notification={notification}
                    />
                ))
            }/>
        }/>
    );
}

const StyledNotificationDisplay = styled.div`
  
`;

export const NotificationDisplay: FC<{
    notification: NotificationPrototype.Notification
}> = props => {
    const { notification } = props;
    const center = NotificationPrototype.useNotifications();
    const t = useTriton();

    return (
        <StyledNotificationDisplay>
            <motion.div
                layout
                initial={{
                    opacity: 0,
                    y: 30
                }}
                animate={{
                    opacity: 1,
                    y: 0,
                    transition: {
                        duration: 0.15
                    }
                }}
                exit={{
                    opacity: 0,
                    // y: 20,
                    x: "-100%",
                    transition: {
                        duration: 0.3
                    }
                }}
                style={{
                    // padding: 8,
                    // borderRadius: 8,
                    padding: 10,
                    borderRadius: 10,
                    backgroundColor: t.col("bg_modal"),
                    marginBottom: 8,
                    display: "flex",
                    gap: 6,
                    flexDirection: "column",
                    position: "relative",

                    // borderLeft: "4px solid crimson"
                }}
                children={
                    <>

                        <NotificationDisplayHeader notification={notification}/>

                        { notification.body && (
                            <NotificationDisplayBody notification={notification}/>
                        ) }

                        { notification.customRenderer && notification.customRenderer() }
                    </>
                }
            />
        </StyledNotificationDisplay>
    );
}

const StyledNotificationDisplayHeader = styled.div<{
    t: Triton
}>`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  align-items: center;
  gap: 8px;
  
  .header-right, .header-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    gap: 6px;
  }
  
  .header-left {
    // flex-shrink: 0;
    flex-grow: 2;
    
    .notification-icon-container {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      aspect-ratio: 1 / 1;
      
      svg {
        color: ${p => p.t.col("fg_secondary")};
        width: 16px;
        aspect-ratio: 1 / 1;
      }
    }
    
    .header-title {
      flex-shrink: 2;
      cursor: initial;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: ${p => p.t.col("fg_default")};
    }
  }
  
  .header-right {
    flex-shrink: 0;
    
    svg {
      color: ${p => p.t.col("fg_secondary")};
      width: 16px;
      aspect-ratio: 1 / 1;
      cursor: pointer;
    }
  }
`;

export const NotificationDisplayHeader: FC<{
    notification: NotificationPrototype.Notification
}> = props => {
    const { notification } = props;
    const center = NotificationPrototype.useNotifications();
    const t = useTriton();

    let titleContent = notification.title ?? notification.body;

    const stdIconRenderer = (): ReactNode => {
        switch (notification.level) {
            case NotificationPrototype.NotificationLevel.TRACE:
            case NotificationPrototype.NotificationLevel.DEBUG:
                return (
                    <BugReportRounded/>
                );
            case NotificationPrototype.NotificationLevel.NORMAL:
                return (
                    <InfoRounded/>
                );
            case NotificationPrototype.NotificationLevel.WARNING:
                return (
                    <WarningAmberRounded/>
                );
            case NotificationPrototype.NotificationLevel.ERROR:
                return (
                    <ErrorRounded/>
                );
        }
    }

    const iconRenderer = (): ReactNode => {
        if (notification.icon === undefined) return stdIconRenderer();
        return notification.icon;
    }

    return (
        <StyledNotificationDisplayHeader t={t}>
            <div className={"header-left"}>
                <div className={"notification-icon-container"}>
                    { iconRenderer() }
                </div>

                <DescriptiveTypography
                    baseProps={{
                        className: "header-title"
                    }}
                    text={titleContent}
                />
            </div>
            <div className={"header-right"}>
                <MoreVertRounded/>
                <CloseRounded
                    onClick={() => center._getNotificationCtx(notification.id).destroy()}
                />
            </div>

        </StyledNotificationDisplayHeader>
    );
}

const StyledNotificationDisplayBody = styled.p<{
    t: Triton
}>`
  padding: 0;
  margin: 0;
  align-items: center;
  font-size: 12px;
  color: ${p => p.t.col("fg_secondary")};
  // font-family: "JetBrains Mono",monospace;
`;

export const NotificationDisplayBody: FC<{
    notification: NotificationPrototype.Notification
}> = props => {
    const { notification } = props;
    const center = NotificationPrototype.useNotifications();
    const t = useTriton();

    const { body } = notification;
    if (body === undefined) return <></>;

    const renderBodyElement = (obj: any): ReactNode => {
        if (Array.isArray(obj)) {
            return (
                <ReactJson src={obj}/>
            );
        }

        const objType = typeof obj;
        switch (objType) {
            case "undefined":
                return "undefined"
            case "object":
                return (
                    <ReactJson src={obj}/>
                );
            case "boolean":
            case "number":
                case "string":
                return obj;
            case "function":
                return (
                    <Tag
                        applyActiveScaling
                        tag={obj.name || "fn"}
                        onClick={() => obj.call()}
                    />
                );
            case "symbol":
            case "bigint":
                break;
        }

        return undefined;
    }

    return (
        <StyledNotificationDisplayBody t={t} children={
            body.map(obj => renderBodyElement(obj))
        }/>
    );
}
