import {Configurable} from "../../../sat/common/core/Configurable";
import {Omit} from "react-beautiful-dnd";
import {SelectionMacro} from "./SelectionMacro";
import {ReactNode} from "react";
import {FunctionsRounded} from "@mui/icons-material";

export type SelectionMacroWithDisplayData = {
    macro: SelectionMacro,
    label?: string,
    iconFactory: () => ReactNode
}

export class SelectionMacroWithDisplayDataBuilder extends Configurable<SelectionMacroWithDisplayData, "macro"> {

    public build(): SelectionMacroWithDisplayData {
        return this.config;
    }

    protected get defaultConfig(): Readonly<Omit<SelectionMacroWithDisplayData, "macro">> {
        return {
            // label: "",
            iconFactory: () => (
                <FunctionsRounded sx={{
                    fontSize: 14
                }}/>
            )
        };
    }
}
