import {Configurable} from "../../../sat/common/core/Configurable";
import {MacroImplementation} from "./MacroImplementation";
import {MacroGuard} from "./MacroGuard";

export type MacroConfig<Context> = {
    readonly guard: MacroGuard<Context>,
    readonly implementation: MacroImplementation<Context>
}

export class Macro<Context> extends Configurable<MacroConfig<Context>> {

    constructor(impl: MacroImplementation<Context>, guard: MacroGuard<Context> = () => true) {
        super({
            implementation: impl,
            guard
        });
    }

    public isExecutable(ctx: Context): boolean {
        return this.config.guard(ctx);
    }

    public async run(ctx: Context): Promise<void> {
        if (!this.isExecutable(ctx)) return;
        const impl = this.config.implementation;
        const _ = await impl.execute.call(ctx);
    }

    protected get defaultConfig(): Readonly<Omit<MacroConfig<Context>, never>> {
        return {
            guard: () => true,
            implementation: {
                async execute(): Promise<void> {}
            }
        };
    }
}







