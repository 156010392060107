import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {Instruction, InstructionImpl, Program, StackMachine} from "./MTAMPrototype";
import React, {FC} from "react";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {Std} from "../../../../../Std";
import {MenuGroup} from "../../../../ardai/components/MenuGroup";
import {ChevronRightRounded, CircleRounded} from "@mui/icons-material";
import {Tag} from "../../../../ardai/components/Tag";
import {useForceRenderFunc} from "../../../../ForceRerenderHook";
import {Dot} from "../../../../ardai/components/Dot";
import {useTriton} from "../../../../triton/TritonHooks";
import also = Std.also;

export const NodeVirtualStackMachine: NodeSetupInfo = {
    label: "NodeVirtualStackMachine",
    classname: "vsm.stack-machine",
    parameterConfig: [],
    factory(parameters: Map<string, any>): Node {
        return new NodeVirtualStackMachineClass()
    }
}

export class NodeVirtualStackMachineClass extends Node {

    constructor() {
        super({
            id: v4(),
            label: "stack machine",
            classname: "vsm.stack-machine",
            customRenderer: node => {
                return (
                    <NodeVirtualStackMachineDisplay node={node as NodeVirtualStackMachineClass}/>
                );
            }
        });
    }

    public machine = also(new StackMachine(), machine => {
        machine.createInstruction(new InstructionImpl("CALL", function (machine, ins) {
            machine.stack.push(machine.register("LB").read());
            machine.stack.push(machine.CP.read() + 1);
            // const Pointer = ins.d + machine.register(ins.r).read();
            machine.CP.write(ins.d); // d[CB]
            machine.register("LB").write(machine.stack.size() - 2);
        }));

        machine.createInstruction(new InstructionImpl("RETURN", function (machine, ins) {
            const returnData: Array<any> = machine.stack.popN(ins.n);
            console.log("return data: ", returnData)

            // TODO: Delete dynamic data

            machine.stack.shrink(machine.register("LB").read() + 2); // TODO Use StackTop register instead


            machine.CP.write(machine.stack.pop())
            machine.register("LB").write(machine.stack.pop())
            machine.stack.popN(ins.d);
            machine.stack.push(...returnData);
        }));

        machine.createInstruction(new InstructionImpl("HALT", function () {
            machine.flagHalted = true;
            console.log("machine halted")
        }));

        machine.createInstruction(new InstructionImpl("LOAD", function (machine, ins) {
            const data = machine.stack.getN(machine.register(ins.r).read(), ins.d, ins.n);
            machine.stack.push(...data);
        }));

        machine.createInstruction(new InstructionImpl("LOADA", function (machine, ins) {
            machine.stack.push(ins.d + machine.register(ins.r).read());
        }));

        machine.createInstruction(new InstructionImpl("LOADL", function (machine, ins) {
            machine.stack.push(ins.d);
        }));

        machine.createInstruction(new InstructionImpl("PUSH", function (machine, ins) {
            const initializedWords: Array<any> = [];
            for (let i = 0; i < ins.d; i++) {
                initializedWords.push(0);
            }
            machine.stack.push(...initializedWords);
        }));

        machine.createInstruction(new InstructionImpl("STOREI", function (machine, ins) {
            const addr = machine.stack.pop();
            const data: Array<any> = [];
            for (let i = 0; i < ins.n; i++) {
                data.push(machine.stack.pop());
            }
            machine.stack.storeAt(addr, data);
        }));

        machine.createInstruction(new InstructionImpl("addI", function (machine, ins) {
            const y = Number(machine.stack.pop());
            const x = Number(machine.stack.pop());
            machine.stack.push(x + y);
        }));

        machine.createInstruction(new InstructionImpl("subI", function (machine, ins) {
            const y = Number(machine.stack.pop());
            const x = Number(machine.stack.pop());
            machine.stack.push(x - y);
        }));

        machine.createInstruction(new InstructionImpl("mulI", function (machine, ins) {
            const y = Number(machine.stack.pop());
            const x = Number(machine.stack.pop());
            machine.stack.push(x * y);
        }));

        machine.createInstruction(new InstructionImpl("nop", function () {}));

        machine.createInstruction(new InstructionImpl("_dumpCore", function (machine, ins) {
            console.log(machine.createSnapshot());
        }));

        const _ = 0;
        machine.loadProgram(new Program([
            new Instruction("CALL", _, 6, "CP"), // main
            new Instruction("HALT"),
            // function int calculate(int, int)
            new Instruction("LOAD", 1, -1, "LB"),
            new Instruction("LOAD", 1, -2, "LB"),
            new Instruction("mulI", _, _),
            new Instruction("RETURN", 1, 2),
            // function void main()
            new Instruction("LOADL", _, 42),
            new Instruction("PUSH", _, 1),
            new Instruction("LOAD", 1, 2, "LB"),
            new Instruction("LOAD", 1, 2, "LB"),
            new Instruction("addI"),
            new Instruction("LOADL", _, 21),
            new Instruction("CALL", _, 2, "CP"), // calculate
            new Instruction("LOADA", _, 3, "LB"),
            new Instruction("STOREI", 1),
            new Instruction("RETURN", 0, 0)
        ]));

        // machine.loadProgram(new Program([
        //     new Instruction("LOADL", _, 30),
        //     new Instruction("LOADL", _, 20),
        //     new Instruction("CALL", _, 3, "CP"),
        //     new Instruction("_dumpCore"),
        //     new Instruction("HALT"),
        // ]))
    })

    clock = this.pins.helpers.clock().attachOnRead(() => {
        this.machine.runNextInstruction();
    });
}

const NodeVirtualStackMachineDisplay: FC<{
    node: NodeVirtualStackMachineClass
}> = props => {
    const { node } = props;
    const vm = node.machine;
    const t = useTriton();

    const forceRender = useForceRenderFunc();

    return (
        <div style={{
            display: "flex",
            gap: 4,
            flexDirection: "column",
            paddingBottom: 8,
            paddingTop: 8,
        }}>
            <div style={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                alignItems: "center"
            }}>
                <Tag tag={"reset"} onClick={() => {
                    vm.CP.write(0);
                    vm.register("LB").write(0);
                    vm.stack.clear();
                    vm.flagHalted = false;
                    vm.currentInstructionCodePoint = undefined;
                    vm.currentInstruction = undefined;
                    forceRender();
                }}/>

                <Dot/>

                <Tag tag={"run"} onClick={() => {
                    vm.run();
                    forceRender();
                }}/>

                <Tag tag={"next"} onClick={() => {
                    vm.runNextInstruction();
                    forceRender();
                }}/>
            </div>

            <DescriptiveTypography text={`CB : ${vm.CP.read()}`}/>
            <DescriptiveTypography text={`LB : ${vm.register("LB").read()}`}/>
            <DescriptiveTypography text={`ins: ${vm.instructionCounter}`}/>
            <DescriptiveTypography text={`hlt: ${vm.flagHalted}`}/>

            <MenuGroup title={"Code"}>
                <div style={{
                    // width: "250px",
                }}>
                    {vm.program.instructions.map((ins, idx) => (
                        <div style={{
                            display: "grid",
                            gap: 2,
                            gridTemplateColumns: "14px min-content  auto",
                            alignItems: "center",
                            backgroundColor: vm.currentInstructionCodePoint === idx ? "rgba(241, 74, 52, 0.1)" : undefined
                        }}>
                            {vm.CP.read() === idx && (
                                <ChevronRightRounded sx={{
                                    fontSize: 18,
                                    color: "#f14a34"
                                }}/>
                            ) || <div/>}

                            <DescriptiveTypography
                                text={`${String(idx).padStart(4, " ")} `}
                                style={{
                                    opacity: .4,
                                    whiteSpace: "pre"
                                }}/>

                            <DescriptiveTypography
                                text={`${ins.opcode.padEnd(12, " ")} (${ins.n}) ${ins.d}[${ins.r}]`}
                                style={{
                                    whiteSpace: "pre"
                                }}/>
                        </div>
                    ))}
                </div>
            </MenuGroup>

            <MenuGroup title={"Stack"}>
                <div>
                    {vm.stack.data.map((word, idx) => (
                        <div style={{
                            display: "grid",
                            gap: 2,
                            gridTemplateColumns: "14px min-content auto",
                            alignItems: "center",
                        }}>
                            {vm.register("LB").read() === idx && (
                                <CircleRounded sx={{
                                    fontSize: 10,
                                    color: "#6849d6"
                                }}/>
                            ) || <div/>}

                            <DescriptiveTypography
                                text={`${String(idx).padStart(4, " ")} `}
                                style={{
                                    opacity: .4,
                                    whiteSpace: "pre"
                                }}/>

                            <DescriptiveTypography key={`${idx}-${word}`} text={`${word}`}
                                                   style={{
                                                       whiteSpace: "pre"
                                                   }}/>
                        </div>
                    ))}
                </div>
            </MenuGroup>
        </div>
    );
}
