import {FC, ReactNode} from "react";
import {DataRiggingTarget} from "../data/DataRiggingTarget";

export type RiggingTargetRendererProps = {
    target: DataRiggingTarget
}

export const RiggingTargetRenderer: FC<RiggingTargetRendererProps> = props => {
    const { target } = props;

    switch (target.targetType) {

        case "inline": {
            return (
                <>
                    {
                        (target.target as () => ReactNode)()
                    }
                </>
            )
        }

        default: {
            return (
                <></>
            );
        }
    }
}
