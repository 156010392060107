import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";

export const NodeSignInverter: NodeSetupInfo = {
    label: "Sign inverter",
    classname: "arithmetic.sign-inverter",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "arithmetic.sign-inverter",
        label: "sign inv.",
        defOutPins: ["y"],
        defInPins: ["x"],
        init: function () {
            this.pins.allDefaultIn.map(pin => {
                pin.attach({
                    read(num: any) {
                        num = Number(num ?? 0);
                        this.node.pins.out("y").write(num * (-1))
                    }
                })
            })
        }
    })
}
