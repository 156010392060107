import {FC} from "react";
import {DataRiggingBone} from "../data/DataRiggingBone";

// noinspection ES6UnusedImports
import {extend} from "@react-three/fiber";

export const BasicCylindricalVolume: FC<{
    bone: DataRiggingBone
}> = props => {
    const { bone } = props;
    const len = bone.length;

    return <></>;

    return (
        <mesh>
            <cylinderGeometry args={[.05, .05, len, 32]}/>
            <meshPhongMaterial specular={0xc1c1c1} shininess={250}/>
        </mesh>
    );
}