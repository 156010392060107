import {NodeEnvironment} from "../../host/NodeEnvironment";
import {Node} from "../../backend/Node";
import {Macro} from "./Macro";

export class SelectionMacro extends Macro<{
    env: NodeEnvironment,
    selection: Array<Node>
}> {}


const deleteAll = new SelectionMacro({
    async execute(): Promise<void> {
        this.selection.forEach(node => {
            this.env.deleteNode(node.id)
        })
    }
})

const cutWires = new SelectionMacro({
    async execute(): Promise<void> {
        this.selection.forEach(node => {
            node.cutLoose();
        })
    }
})
