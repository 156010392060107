import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {FC, useEffect, useState} from "react";
import styled from "styled-components";
import {MotionConfig, Reorder} from "framer-motion";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {Tag} from "../../../../ardai/components/Tag";
import {useNodeCanvasBackend} from "../../NodeCanvasBackend";
import {useForceRenderFunc} from "../../../../ForceRerenderHook";
import {useStaticState} from "../../../../ardai/hooks/StaticStateHook";
import {NodeDisplayState} from "../../NodeDisplay";
import {DeleteRounded, EditRounded, VisibilityRounded} from "@mui/icons-material";

export const NodeDashboard: NodeSetupInfo = {
    label: "NodeDashboard",
    classname: "visualization.dashboard",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "visualization.dashboard",
        label: "dashboard",
        state: {

        },
        customRenderer: node => (
            <NodeDashboardDisplay
                initialTargetNodeIDs={parameters.get("initialTargetNodeIDs")}
            />
        )
    })
}

const StyledNodeDashboardDisplay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0;
  
  .tabs {
    padding: 0;
    margin: 0;
    // flex-grow: 1;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-wrap: nowrap;
    list-style: none;
    gap: 4px;
  }
`;

type NodeDashboardDisplayProps = {
    initialTargetNodeIDs?: Array<string>
}

enum NodeDashboardDisplayMode {
    VIEW, EDIT
}

type NodeDashboardDisplayState = {
    mode: NodeDashboardDisplayMode
}

export const NodeDashboardDisplay: FC<NodeDashboardDisplayProps> = props => {
    const canvasBackend = useNodeCanvasBackend();
    const [items, setItems] = useState<(string | undefined)[]>(props.initialTargetNodeIDs ?? []);

    const [state, ctx] = useStaticState<NodeDashboardDisplayState>({
        id: "NodeDashboardDisplay-local",
        staticMode: false,
        initial: {
            mode: NodeDashboardDisplayMode.VIEW
        }
    }).stateWithCtx;

    return (
        <StyledNodeDashboardDisplay>
            <MotionConfig reducedMotion={"always"}>
                <Reorder.Group
                    className={"tabs"}
                    as={"ul"}
                    axis={"x"}
                    layout={"preserve-aspect"}
                    values={items}
                    onReorder={setItems}
                    // style={{
                    //     alignItems: "end",
                    //     justifyContent: "stretch"
                    // }}
                    children={items.map((item, idx) => (
                        <Reorder.Item key={item} value={item} style={{
                            alignSelf: "stretch"
                        }}>
                            <div style={{
                                display: "flex",
                                // display: "grid",
                                gap: 4,
                                flexDirection: "column",
                                justifyContent: "space-between",
                                alignItems: "end",
                                height: "100%"
                            }}>
                                { state.mode === NodeDashboardDisplayMode.VIEW && (
                                    <div style={{
                                        width: "100%",
                                        display: "grid",
                                        gap: 4,
                                    }}>
                                        <Tag tag={"="} onClick={() => {
                                            setItems(prevState => {
                                                const newState = [...prevState];
                                                const selectedNode = canvasBackend.state.ui.selection.prime.selectedNodeId;
                                                if (selectedNode === undefined) return newState;
                                                newState[idx] = selectedNode;
                                                return newState;
                                            })
                                        }}/>
                                        <Tag tag={
                                            <DeleteRounded sx={{ fontSize: 14 }}/>
                                        } onClick={() => {
                                            setItems(prevState => {
                                                const newState = [...prevState];
                                                newState.splice(idx, 1);
                                                return newState;
                                            })
                                        }}/>
                                    </div>
                                ) }
                                { item && (
                                    <NodeCellRenderer nodeId={item}/>
                                ) }
                            </div>
                        </Reorder.Item>
                    ))}
                />
            </MotionConfig>
            <div style={{
                display: "flex",
                gap: 4,
                alignItems: "center"
            }}>
                <Tag tag={"+"} onClick={() => setItems(prevState => [...prevState, undefined])}/>
                <Tag
                    tag={state.mode === NodeDashboardDisplayMode.VIEW ? (
                        <EditRounded sx={{ fontSize: 14 }}/>
                    ) : (
                        <VisibilityRounded sx={{ fontSize: 14 }}/>
                    )}
                    onClick={() => ctx.update(prevState => ({
                        mode: prevState.mode === NodeDashboardDisplayMode.VIEW ?
                            NodeDashboardDisplayMode.EDIT :
                            NodeDashboardDisplayMode.VIEW
                    }))}
                />
            </div>
        </StyledNodeDashboardDisplay>
    );
}

const NodeCellRenderer: FC<{
    nodeId: string
}> = props => {
    const { nodeId } = props;
    const canvasBackend = useNodeCanvasBackend();
    const correspondingNode = canvasBackend.environment.getNodeByID(nodeId);

    // TODO: Be less verbose
    const forceRender = useForceRenderFunc();
    useEffect(() => correspondingNode.observer.observeGlobally().on(() => {
        forceRender();
    }).destructor, []);

    return <>{
        correspondingNode?.config.customRenderer?.(correspondingNode) ?? (
            <DescriptiveTypography text={"?"}/>
        )
    }</>;
}
