import {Canvas, MeshProps, useFrame, useLoader, Vector3} from "@react-three/fiber";
import {FC, useEffect, useMemo, useRef} from "react";
import {BufferGeometry, Mesh, TextureLoader, Vector2} from "three";
import {DataRiggingBone} from "./common/data/DataRiggingBone";
import {DataRiggingAnchorType} from "./common/data/DataRiggingAnchorType";
import {DataObject} from "./common/data/DataObject";
import {Object} from "./common/core/Object";
import {Configurable} from "./common/core/Configurable";
import {Omit} from "react-beautiful-dnd";
import {HSVToRGBUtils} from "../utils/color/HSVToRGBUtils";
import {useForceRenderFunc} from "../ForceRerenderHook";
import {DataRiggingAnchor} from "./common/data/DataRiggingAnchor";
import {DataEuler} from "./common/data/DataEuler";
import {degToRad, mapLinear} from "three/src/math/MathUtils";
import {ColorRepresentation} from "three/src/math/Color";
import {useControls} from "leva";
import {Environment, GizmoHelper, GizmoViewport, Grid, MeshDistortMaterial, OrbitControls} from "@react-three/drei";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import ImageSrc from "./anatomy.png";
import {createInlineRiggingTarget} from "./common/core/CreateInlineRiggingTargetUtility";
import {Std} from "../../Std";
import also = Std.also;

export const SATTestMain = () => {
    return (
        <SATTestMainSub/>
    );
}

const SATTestMainSub = () => {

    const rig = useMemo(() => new TestRig().rig, []);

    const sceneControls = useControls("Scene", {
        // background: "#303035",
        background: "#000000",
        environment: "studio"
    });

    const gridControls = useControls("Grid", {
        enable: false,
        position: [0, -0.01, 0],
        gridSize: [10.5, 10.5],
        cellSize: { value: 0.6, min: 0, max: 10, step: 0.1 },
        cellThickness: { value: 1, min: 0, max: 5, step: 0.1 },
        cellColor: '#6f6f6f',
        sectionSize: { value: 3.3, min: 0, max: 10, step: 0.1 },
        sectionThickness: { value: 1.5, min: 0, max: 5, step: 0.1 },
        sectionColor: '#94c083',
        fadeDistance: { value: 25, min: 0, max: 100, step: 1 },
        fadeStrength: { value: 1, min: 0, max: 1, step: 0.1 },
        followCamera: false,
        infiniteGrid: true
    });

    const referenceControls = useControls("Ref", {
        enable: false
    });

    const imageMap = useLoader(TextureLoader, ImageSrc);

    return (
        <Canvas shadows={true} style={{
            background: sceneControls.background
        }}>
            {/*
            <ambientLight
                intensity={Math.PI / 2}
            />
            */}
            <ambientLight
                intensity={Math.PI / 2}
                color={"#8ef387"}
            />
            <pointLight
                position={[-10, -10, -10]}
                decay={0}
                intensity={Math.PI}
                color={"orange"}
            />
            <spotLight
                position={[10, 10, 10]}
                angle={0.15}
                penumbra={1}
                decay={0}
                intensity={Math.PI}
                color={"pink"}
            />

            {gridControls.enable && (
                <Grid args={gridControls.gridSize} {...gridControls} side={2} />
            )}

            <RigRenderer rig={rig}/>

            <Environment preset={sceneControls.environment as any}/>

            <OrbitControls makeDefault/>

            <GizmoHelper alignment={"bottom-right"} margin={[80, 80]}>
                <GizmoViewport axisColors={['#9d4b4b', '#2f7f4f', '#3b5b9d']} labelColor="white"/>
            </GizmoHelper>

            {(() => {
                if (!referenceControls.enable) return null;

                const posY = 0;
                const h = 6;

                const imageH = imageMap.image.height;
                const imageW = imageMap.image.width;
                const ratio = imageH / h;
                const w = imageW / ratio;

                return (
                    <mesh position={[0, posY, 0]}>
                        <planeGeometry args={[w, h]}/>
                        <meshBasicMaterial
                            side={2}
                            map={imageMap}
                            // color={"#8ef387"}
                            transparent={true}
                            opacity={.9}
                        />
                    </mesh>
                );
            })()}
        </Canvas>
    );
}

export class TestRig {

    public readonly rig: DataObject;

    constructor() {

        const createHead = () => createInlineRiggingTarget({
            renderer(ctx) {
                const gltf = useLoader(GLTFLoader, '/assets/sat/models/human/head.gltf');
                const scale = .13;
                return (
                    <mesh position={[0, .085, .165]} scale={[scale, scale, scale]} rotation={[0, 0, Math.PI]}>
                        <primitive object={gltf.scene} />
                        {ctx.children}
                    </mesh>
                );

                // return (
                //     <mesh scale={.9}>
                //         <boxGeometry args={[
                //             .5, .6, .7, // sizing
                //             1, 1, 1 // segmentation
                //         ]}/>
                //         { ctx.children }
                //     </mesh>
                // );
            }
        })


        const createNeck = () => createInlineRiggingTarget({
            renderer(ctx) {
                const neckDiameter = .125;
                return (
                    <mesh>
                        <cylinderGeometry args={[neckDiameter, neckDiameter, .2, 32]}/>
                        {ctx.children}
                    </mesh>
                );
            }
        })

        const createStomachPlaceholder = (config: Partial<{
            diameter: number;
        }> = {}) => createInlineRiggingTarget({
            renderer(ctx) {
                const baseScale = 1;
                const diameter = config.diameter ?? .35;
                const scale: Vector3 = [baseScale, baseScale, baseScale * .6];
                return (
                    <mesh scale={scale}>
                        <cylinderGeometry args={[diameter, diameter, .4, 32]}/>
                        {ctx.children}
                    </mesh>
                );
            }
        })

        const createRibCage = () => createInlineRiggingTarget({
            renderer(ctx) {
                const points = [];
                for (let i = 0; i < 10; i++) points.push(new Vector2(
                    Math.sin(i * 0.2) * 10 + 5,
                    (i - 5) * 2)
                );
                const baseScale = .025;
                const scale: Vector3 = [baseScale, baseScale, baseScale * .6];
                return (
                    <mesh scale={scale}>
                        <latheGeometry args={[points]}/>
                        {ctx.children}
                    </mesh>
                );
            }
        })

        const createBeveledCylinder = (
            length: number = 1,
            meshOverwrites: MeshProps = {},
            segmentationRatio: Array<number> = [.3, .45, .25],
            segments: number = 100
        ) => createInlineRiggingTarget({
            renderer(ctx) {
                const points: Array<Vector2> = [];
                const len = length ?? 1;
                const subdivisions = segments ?? 40;
                const minDiameter = .075, maxDiameter = .15

                let i = 0;
                const handle = (
                    amount: number,
                    map: (globalIdx: number, localIdx: number) => Vector2
                ) => {
                    for (let j = 0; j < amount; j++) {
                        const subdivisionIdx = i + j;
                        const vec = map(subdivisionIdx, j);
                        points.push(vec);
                    }
                    i += amount;
                }

                let jurisdiction = subdivisions * segmentationRatio[0] ?? .25;
                handle(jurisdiction, (globalIdx, localIdx) => new Vector2(
                    mapLinear(localIdx, 0, jurisdiction, minDiameter, maxDiameter),
                    globalIdx
                ))

                jurisdiction = subdivisions * segmentationRatio[1] ?? .5;
                handle(jurisdiction, (globalIdx) => new Vector2(
                    maxDiameter,
                    globalIdx
                ))

                jurisdiction = subdivisions * segmentationRatio[2] ?? .25;
                handle(jurisdiction, (globalIdx, localIdx) => new Vector2(
                    mapLinear(localIdx, 0, jurisdiction, maxDiameter, minDiameter),
                    globalIdx
                ))

                points.forEach(p => p.y /= subdivisions);
                points.forEach(p => p.y *= len);


                const baseScale = 1;
                const scale: Vector3 = [baseScale * .75, baseScale, baseScale];
                return (
                    <mesh scale={scale} position={[0, -.5 * len, 0]} {...meshOverwrites}>
                        <latheGeometry args={[points]}/>
                        {ctx.children}
                    </mesh>
                );
            }
        })

        const createLowerArm = () => createBeveledCylinder(
            .8,
            {
                scale: [1, 1, 1]
            },
            [.7, .0, .3]
        );

        const createUpperLeg = () => createInlineRiggingTarget({
            renderer(ctx) {
                return (
                    <mesh>
                        <cylinderGeometry args={[.2, .17, 1, 32]}/>
                        { ctx.children }
                    </mesh>
                );
            }
        })

        const createLowerLeg = () => createInlineRiggingTarget({
            renderer(ctx) {
                return (
                    <mesh position={[0, 0, -.05]}>
                        <cylinderGeometry args={[.15, .09, 1, 32]}/>
                        { ctx.children }
                    </mesh>
                );
            }
        })

        const createFoot = () => {
            return createInlineRiggingTarget({
                renderer(ctx) {
                    return (
                        <mesh scale={[1, 1, .6]}>
                            <cylinderGeometry args={[.15, .09, .6, 32]}/>
                            { ctx.children }
                        </mesh>
                    );
                }
            })
        }

        this.rig = {
            id: "human",
            name: "human",
            root: {
                target: {
                    // hip
                    rotation: 0,
                    length: 0,
                    target: this.createPelvis(),
                    anchors: [
                        // spine
                        [0, {
                            type:DataRiggingAnchorType.FIXED,
                            transformation: {
                                x: -.1 * Math.PI,
                                y: 0,
                                z: Math.PI
                            },
                            target: new SegmentedAnatomy({
                                segments: 10,
                                length: 1.35,
                                angleMappingX: function (x) {
                                    return Math.sin((x - .5 * Math.PI) * .85) * .135
                                },
                                colorMapping: function () {
                                    const color = HSVToRGBUtils.HSVtoRGB(this.trackPercentageNormalized * .5, .2, 1);
                                    return `rgb(${color.r}, ${color.g}, ${color.b})`;
                                },
                                configureBone(bone, i) {
                                    switch (i) {
                                        case 2: {
                                            bone.target = createStomachPlaceholder({
                                                diameter: .3
                                            });
                                            break;
                                        }
                                        case 4: {
                                            bone.target = createStomachPlaceholder();
                                            break;
                                        }
                                        case 7: {
                                            bone.target = createRibCage();
                                        }
                                    }
                                },
                                configureEndBone: end => {
                                    end.anchors.push(
                                        // right arm shoulder
                                        [0, {
                                            type: DataRiggingAnchorType.FIXED,
                                            transformation: {
                                                x: 0,
                                                y: 0,
                                                z: .5 * Math.PI + .05 * Math.PI
                                            },
                                            target: {
                                                rotation: 0,
                                                target: null as any,
                                                length: .45,
                                                anchors: [
                                                    [0, new Anchor({
                                                        // left upper arm
                                                        type: DataRiggingAnchorType.BALL,
                                                        target: new Bone({
                                                            target: createBeveledCylinder(),
                                                            anchors: [
                                                                [0, new Anchor({
                                                                    // left lower arm
                                                                    type: DataRiggingAnchorType.HINGE,
                                                                    target: new Bone({
                                                                        length: .8,
                                                                        target: createLowerArm(),
                                                                        anchors: [
                                                                            // hand
                                                                            [0, new Anchor({
                                                                                target: new Bone({
                                                                                    length: .2,
                                                                                }).build()
                                                                            }).rotate("z", 45, "deg").rotate("y", -45, "deg").build()]
                                                                        ]
                                                                    }).build()
                                                                }).rotate("x", -90, "deg").build()]
                                                            ]
                                                        }).build()
                                                    }).rotate("z", 90 - 15, "deg").build()]
                                                ]
                                            }
                                        }],

                                        // left arm shoulder
                                        [0, {
                                            type: DataRiggingAnchorType.FIXED,
                                            transformation: {
                                                x: Math.PI,
                                                y: 0,
                                                z: -.5 * Math.PI + .05 * Math.PI
                                            },
                                            target: {
                                                rotation: 0,
                                                target: null as any,
                                                length: .45,
                                                anchors: [
                                                    [0, new Anchor({
                                                        // left upper arm
                                                        type: DataRiggingAnchorType.HINGE,
                                                        target: new Bone({
                                                            target: createBeveledCylinder(),
                                                            anchors: [
                                                                [0, new Anchor({
                                                                    // left lower arm
                                                                    type: DataRiggingAnchorType.HINGE,
                                                                    target: new Bone({
                                                                        length: .8,
                                                                        target: createLowerArm(),
                                                                        anchors: [
                                                                            // hand
                                                                            [0, this.createHandRig()
                                                                                .rotate("z", 45, "deg")
                                                                                .rotate("y", 45, "deg")
                                                                                .build()
                                                                            ]
                                                                        ]
                                                                    }).build()
                                                                }).rotate("x", 90, "deg").build()]
                                                            ]
                                                        }).build()
                                                    }).rotate("z", 90 - 15, "deg").build()]
                                                ]
                                            }
                                        }],

                                        // neck
                                        [0, {
                                            type: DataRiggingAnchorType.BALL,
                                            transformation: {
                                                x: Math.PI * -.125,
                                                y: Math.PI * -.1,
                                                z: 0
                                            },
                                            target: {
                                                rotation: 0,
                                                target: createNeck(),
                                                length: .2,
                                                anchors: [
                                                    [0, new Anchor({
                                                        // head
                                                        type: DataRiggingAnchorType.BALL,
                                                        target: new Bone({
                                                            length: .5,
                                                            target: createHead()
                                                        }).build()
                                                    }).rotate("x", 0, "deg").build()]
                                                ]
                                            }
                                        }]

                                    )
                                }
                            }).build()
                        }],

                        // left leg
                        [0, {
                            type: DataRiggingAnchorType.FIXED,
                            transformation: {
                                x: 0,
                                y: 0,
                                z: .5 * Math.PI
                            },
                            target: {
                                // hip extender left
                                rotation: 0,
                                length: .25,
                                target: null as any,
                                anchors: [
                                    [0, {
                                        type: DataRiggingAnchorType.BALL,
                                        transformation: {
                                            x: Math.PI + Math.PI * .2,
                                            y: Math.PI + -.0 * Math.PI,
                                            z: .5 * Math.PI + .175
                                        },
                                        target: {
                                            // upper leg left
                                            rotation: 0,
                                            length: 1.4,
                                            target: createUpperLeg(),
                                            anchors: [
                                                [0, {
                                                    type: DataRiggingAnchorType.HINGE,
                                                    transformation: {
                                                        x: 0,
                                                        y: 0,
                                                        z: 0
                                                    },
                                                    target: {
                                                        // lower leg left
                                                        rotation: 0,
                                                        length: 1.4,
                                                        target: createLowerLeg(),
                                                        anchors: [
                                                            [0, {
                                                                type: DataRiggingAnchorType.HINGE,
                                                                transformation: {
                                                                    x: -.5 * Math.PI,
                                                                    y: 0,
                                                                    z: 0
                                                                },
                                                                target: {
                                                                    // foot left
                                                                    rotation: 0,
                                                                    length: .35,
                                                                    target: createFoot(),
                                                                    anchors: []
                                                                }
                                                            }]
                                                        ]
                                                    }
                                                }]
                                            ]
                                        }
                                    }]
                                ]
                            }
                        }],

                        // right leg
                        [0, {
                            type: DataRiggingAnchorType.FIXED,
                            transformation: {
                                x: 0,
                                y: 0,
                                z: -.5 * Math.PI
                            },
                            target: {
                                // hip extender right
                                rotation: 0,
                                length: .25,
                                target: null as any,
                                anchors: [
                                    [0, {
                                        type: DataRiggingAnchorType.BALL,
                                        transformation: {
                                            x: .5,
                                            y: 0,
                                            z: .5 * Math.PI
                                        },
                                        target: {
                                            // upper leg right
                                            rotation: 0,
                                            length: 1.4,
                                            target: createUpperLeg(),
                                            anchors: [
                                                [0, {
                                                    type: DataRiggingAnchorType.HINGE,
                                                    transformation: {
                                                        x: 0,
                                                        y: 0,
                                                        z: 0
                                                    },
                                                    target: {
                                                        // lower leg right
                                                        rotation: 0,
                                                        length: 1.4,
                                                        target: createLowerLeg(),
                                                        anchors: [
                                                            [0, {
                                                                type: DataRiggingAnchorType.HINGE,
                                                                transformation: {
                                                                    x: -.5 * Math.PI,
                                                                    y: 0,
                                                                    z: 0
                                                                },
                                                                target: {
                                                                    // foot right
                                                                    rotation: 0,
                                                                    length: .35,
                                                                    target: createFoot(),
                                                                    anchors: []
                                                                }
                                                            }]
                                                        ]
                                                    }
                                                }]
                                            ]
                                        }
                                    }]
                                ]
                            }
                        }]
                    ]
                },
                type: DataRiggingAnchorType.FIXED,
                transformation: {
                    x: 0,
                    y: 0,
                    z: 0
                }
            },
            position: {
                x: 0,
                y: 0,
                z: 0
            }
        }
    }

    private createFingerSegment(config: Partial<{
        length: number,
        maxDiameter: number,
        minDiameter: number,
        scale: Vector3
    }>) {
        const maxDiameter = config.maxDiameter ?? .02;

        return createInlineRiggingTarget({
            renderer(ctx) {
                return (
                    <mesh scale={config.scale ?? [1, 1, 1]}>
                        <cylinderGeometry args={[maxDiameter, maxDiameter, config.length ?? .2, 32]}/>
                        { ctx.children }
                    </mesh>
                );
            }
        })
    }

    private createFingerRig(config: Partial<{
        scale: Vector3
    }>): Bone {
        const fingerBoneStrength = .02;
        const fingerSegmentCurvatureDeg = 10;
        // start segment
        return new Bone({
            strength: fingerBoneStrength,
            length: .05,
            target: this.createFingerSegment({
                length: .05,
                scale: config.scale
            }),
            anchors: [[0, new Anchor({
                // middle segment
                target: new Bone({
                    strength: fingerBoneStrength,
                    length: .05,
                    target: this.createFingerSegment({
                        length: .05,
                        scale: config.scale
                    }),
                    anchors: [[0, new Anchor({
                        // end segment
                        target: new Bone({
                            strength: fingerBoneStrength,
                            length: .034,
                            target: this.createFingerSegment({
                                length: .05,
                                scale: config.scale
                            })
                        }).build()
                    }).rotate("z", fingerSegmentCurvatureDeg, "deg").build()]]
                }).build()
            }).rotate("z", fingerSegmentCurvatureDeg, "deg").build()]]
        });
    }

    private createPalm() {
        return createInlineRiggingTarget({
            renderer(ctx) {
                const gltf = useLoader(GLTFLoader, '/assets/sat/models/human/hand-palm.gltf');
                const scale = .4;
                return (
                    <mesh scale={[scale, scale, scale]} rotation={[-.5 * Math.PI, 0, .5 * Math.PI]}>
                        <primitive object={gltf.scene} />
                        {ctx.children}
                    </mesh>
                );
            }
        })
    }

    private createPelvis() {
        return createInlineRiggingTarget({
            renderer(ctx) {
                const gltf = useLoader(GLTFLoader, '/assets/sat/models/human/pelvis.gltf');
                const scale = .37;

                // gltf.scene.traverse(object => {
                //     if (object.type === "Mesh") {
                //         (object as any).material.color.set("crimson")
                //         console.warn((object as any).geometry)
                //     }
                // })
                // const geometry = new BufferGeometry();
                // geometry.computeVertexNormals();
                // gltf.nodes

                return (
                    <mesh scale={[scale, scale, scale]} rotation={[-.5 * Math.PI, 0, 0]}>
                        <primitive object={gltf.scene}/>
                        {ctx.children}
                    </mesh>
                );
            }
        })
    }

    private createHandRig(combineRindAndIndexFinger: boolean = true): Anchor {
        const boneStrength = .02;
        return new Anchor({
            type: DataRiggingAnchorType.BALL,
            target: new Bone({
                strength: boneStrength,
                length: .2,
                target: this.createPalm(),
                anchors: [
                    // pinky finger bridge
                    [0, new Anchor({
                        target: new Bone({
                            length: .0725,
                            strength: boneStrength,
                            anchors: [[0, new Anchor({
                                target: this.createFingerRig({}).build()
                            }).rotate("x", -90, "deg").build()]]
                        }).build()
                    }).rotate("x", 90 + 10, "deg").build()],

                    // ring + middle finger
                    [0, new Anchor({
                        target: this.createFingerRig({
                            scale: [1, 1, 2]
                        }).build()
                    }).rotate("x", 0, "deg").build()],

                    // index bridge
                    [0, new Anchor({
                        target: new Bone({
                            length: .0725,
                            strength: boneStrength,
                            anchors: [[0, new Anchor({
                                target: this.createFingerRig({}).build()
                            }).rotate("x", 90, "deg").build()]]
                        }).build()
                    }).rotate("x", -90 - 10, "deg").build()],
                ]
            }).build()
        })
    }
}

export const RigRenderer: FC<{
    rig: DataObject
}> = props => {
    const { rig } = props;

    const rifControls = useControls("Rig", {
        enableAutoRotation: false,
        autoRotationX: .2,
        autoRotationY: 0,
        autoRotationZ: 0
    })

    const ref = useRef(new Object({
        data: rig
    }));

    const render = useForceRenderFunc(); // TODO: Does it even do something?

    useEffect(() => {
        ref.current = new Object({
            data: rig
        });
        render();
    }, [rig]);

    const meshRef = useRef<Mesh>(null);

    useFrame((_, delta) => {
        if (!meshRef.current) return;

        autoRotation: {
            if (!rifControls.enableAutoRotation) break autoRotation;
            meshRef.current.rotation.y += delta * rifControls.autoRotationX
            meshRef.current.rotation.y += delta * rifControls.autoRotationY // (main rotation)
            meshRef.current.rotation.z += delta * rifControls.autoRotationZ
        }
    });

    return (
        <mesh ref={meshRef} rotation={[0, 0, 0]}>
            {
                ref.current.render({})
            }
        </mesh>
    );
}

type NumericalMapping = Mapping<number, number, {
    min: number,
    max: number
}>

type Mapping<In, Out, Ctx = {}> = (this: Ctx, key: In) => Out;

type SegmentedAnatomyConfig = {
    segments: number,
    length: number,
    angleMappingX: NumericalMapping,
    configureEndBone: (end: DataRiggingBone) => void,
    configureBone: (end: DataRiggingBone, i: number) => void,
    colorMapping: Mapping<void, ColorRepresentation | undefined, {
        trackPercentage: number,
        trackPercentageNormalized: number,
    }>
}

class SegmentedAnatomy extends Configurable<SegmentedAnatomyConfig> {

    public build(): DataRiggingBone {
        const c = this.config;
        const segmentLen = c.length / c.segments;

        const anatomy: DataRiggingBone = {
            rotation: 0,
            target: null as any,
            length: 0,
            anchors: []
        }

        let head = anatomy;
        for (let i = 0; i < c.segments; i++) {
            const p = (i + 1) * 100 / c.segments;
            const color: ColorRepresentation | undefined = c.colorMapping.call({
                trackPercentage: p,
                trackPercentageNormalized: p / 100
            })

            head.anchors.push([0, {
                type: DataRiggingAnchorType.FIXED,
                transformation: {
                    x: c.angleMappingX.call({
                        min: 0,
                        max: c.segments
                    }, i), y: 0, z: 0
                },
                target: head = also({
                    rotation: 0,
                    target: null as any,
                    length: segmentLen,
                    anchors: [],
                    color
                }, bone => c.configureBone(bone, i))
            }]);
        }

        c.configureEndBone(head);

        return anatomy;
    }

    protected get defaultConfig(): Readonly<Omit<SegmentedAnatomyConfig, never>> {
        return {
            segments: 1,
            length: 1,
            angleMappingX: () => 0,
            configureEndBone: () => {},
            colorMapping: () => undefined,
            configureBone: () => {}
        };
    }
}

class Anchor extends Configurable<DataRiggingAnchor, "target"> {

    private readonly rotation: DataEuler = this.staticConfig.transformation ?? {
        x: 0,
        y: 0,
        z: 0
    }

    build = () => this.config;

    public rotate(axis: "x" | "y" | "z", amount: number, unit: "rad" | "deg" = "rad"): this {
        this.rotation[axis] = unit === "deg" ? degToRad(amount) : amount;
        return this;
    }

    get config(): Readonly<DataRiggingAnchor> {
        return {
            ...this.defaultConfig,
            ...this.staticConfig,
            ...this.configOverwrites,
            transformation: this.rotation
        };
    }

    protected get defaultConfig(): Readonly<Omit<DataRiggingAnchor, "target">> {
        return {
            type: DataRiggingAnchorType.FIXED,
            transformation: {
                x: 0,
                y: 0,
                z: 0
            }
        };
    }
}

class Bone extends Configurable<DataRiggingBone> {

    build = () => this.config;

    protected get defaultConfig(): Readonly<Omit<DataRiggingBone, never>> {
        return {
            anchors: [],
            target: null as any,
            length: 1,
            rotation: 0,
            strength: .05,
        };
    }
}
