import React, {FC} from "react";
import {RiggingBone} from "./RiggingBone";
import {RiggingAnchorRenderer} from "./RiggingAnchorRenderer";
import {RiggingAnchor} from "./RiggingAnchor";
import {BasicCylindricalVolume} from "../volumes/BasicCylindricalVolume";
import {RiggingTargetRenderer} from "./RiggingTargetRenderer";

export const RiggingBoneRenderer: FC<{
    bone: RiggingBone
}> = props => {
    const { bone } = props;
    const data = bone.config.data;
    const len = data.length;
    const boneThickness = data.strength  ?? .05;
    const { target } = data;

    return (
        <mesh
            position={[0, -.5 * len, 0]}
            rotation={[0, 0, 0]}
        >
            <boxGeometry args={[boneThickness, data.length, boneThickness]}/>
            <meshStandardMaterial color={data.color ?? 'white'} wireframe/>

            { target && (
                <RiggingTargetRenderer target={target}/>
            ) }
            <BasicCylindricalVolume bone={data}/>

            {/* anchors */}
            {
                data.anchors.map(([trackPercentage, anchor]) => {
                    const bonePositionOffset = trackPercentage * len;
                    return (
                        <mesh
                            key={trackPercentage} // TODO: Make better key
                            position={[0, bonePositionOffset - .5 * len, 0]}
                            children={
                                <RiggingAnchorRenderer anchor={new RiggingAnchor({
                                    data: anchor
                                })}/>
                            }
                        />
                    );
                })
            }
        </mesh>
    );
}
