import React, {FC} from "react";
import {RiggingAnchor} from "./RiggingAnchor";
import {RiggingBoneRenderer} from "./RiggingBoneRenderer";
import {RiggingBone} from "./RiggingBone";
import {PivotControls} from "@react-three/drei";
import {useAdvancedState} from "../../../ardai/hooks/StaticStateHook";
import {DataRiggingAnchorType} from "../data/DataRiggingAnchorType";
import {ClickAwayListener} from "@mui/base";

export type RiggingAnchorRendererState = {
    selected: boolean
}

export const RiggingAnchorRenderer: FC<{
    anchor: RiggingAnchor
}> = props => {
    const { anchor } = props;

    const [state, ctx] = useAdvancedState<RiggingAnchorRendererState>({
        staticMode: false,
        initial: {
            selected: false
        }
    }).stateWithCtx;

    const rotation = anchor.config.data.transformation;

    const targetBone = anchor.config.data.target;

    const anchorType = anchor.config.data.type;
    const isMovable = anchorType !== DataRiggingAnchorType.FIXED;
    const enablePivotControls = state.selected && isMovable
        && true // TODO: Remove
    ;

    return (
        // socket
        <ClickAwayListener onClickAway={() => ctx.update({
            selected: false
        })}>
            <mesh
                position={[0, 0, 0]}
                rotation={[0, 0, 0]}
                onClick={() => ctx.reverseBool("selected")}
            >
                { isMovable && false && (
                    <boxGeometry args={[.1, .1, .1]}/>
                )}
                {/*
                <meshStandardMaterial color={targetBone.color ?? 'pink'} transparent opacity={.5}/>
                */}

                {/* socket connector */}
                <PivotControls enabled={enablePivotControls} axisColors={['#9d4b4b', '#2f7f4f', '#3b5b9d']}>
                    <mesh rotation={[rotation.x, rotation.y, rotation.z]}>
                        <RiggingBoneRenderer bone={new RiggingBone({
                            data: targetBone
                        })}/>
                    </mesh>
                </PivotControls>
            </mesh>
        </ClickAwayListener>
    );
}
