import {NodeSetupInfo} from "../../../NodeSetupInfo";
import {Node} from "../../../../backend/Node";
import {v4} from "uuid";
import React, {FC, useContext, useEffect} from "react";
import styled from "styled-components";
import {Token} from "./templates/Token";
import {TemplateReader} from "./templates/TemplateReader";
import {Orientation} from "../../../../../base/logic/style/Orientation";
import {Triton} from "../../../../../triton/Triton";
import {useTriton} from "../../../../../triton/TritonHooks";
import {DescriptiveTypography} from "../../../../../triton/components/typography/DescriptiveTypography";

type Template = Array<Token>;

export type NodeAdvancedSevenSegmentDisplayState = {
    template: string,
    tokens: Template,
    value: string,
    reverseFill: boolean
}

/**
 * TODO: Remove?
 */
const newDriver = (): Driver<any, string> => new FloatingPointDriverV2();

export const NodeAdvancedSevenSegmentDisplay: NodeSetupInfo = {
    label: "NodeAdvancedSevenSegmentDisplay",
    classname: "visualization.adv-seven-segment-display",
    parameterConfig: [],
    factory: parameters => {
        const template = parameters.get("template") ?? '___.__';
        const value = parameters.get("value") ?? '0.0';

        return new Node<NodeAdvancedSevenSegmentDisplayState>({
            id: v4(),
            classname: "visualization.adv-seven-segment-display",
            label: "display",
            state: {
                template,
                tokens: [],
                value: value,
                reverseFill: false
            },
            init() {
                this.pins.in("s").attachOnRead(value => {
                    // n = Number(n);

                    this.state.update({
                        // value
                        value: newDriver().transformSource(value, this.state.state.tokens).join("")
                    });
                })
            },
            customRenderer: (node) => (
                <NodeAdvancedSevenSegmentDisplayDisplay
                    node={node}
                />
            )
        });
    }
}

interface Driver<SourceType, ValueTokenType> {
    transformSource(source: SourceType, template: Template): Array<ValueTokenType>;
}

namespace ShiftArray {

    export function shift<T>(array: Array<T>, n: number, filament: T) {
        if (n === 0) return;
        else if (n > 0) right(array, n, filament);
        else left(array, n, filament);
    }

    export function left<T>(array: Array<T>, n: number, filament: T) {
        for (let i = 0; i < n; i++) {
            array.shift()
            array.push(filament);
        }
    }

    export function right<T>(array: Array<T>, n: number, filament: T) {
        for (let i = 0; i < n; i++) {
            // array.pop()
            array.unshift(filament);
        }
    }
}

class FloatingPointDriver implements Driver<any, string> {
    transformSource(source: any, template: Template): Array<string> {
        const n = Number(source);
        const templateVariablesLength = template.filter(token => token.kind === "digit").length;
        let templatePivot = template.findIndex(token => token.annotations.has("dot"));
        if (templatePivot === -1) templatePivot = templateVariablesLength - 1;

        const nS = String(n).split("");
        let sourcePivot = nS.findIndex(c => c === ".");
        if (sourcePivot === -1) sourcePivot = nS.length - 1;

        const sourceToTemplateOffset = templatePivot - sourcePivot;
        const output: string[] = [];
        ShiftArray.shift(nS, sourceToTemplateOffset, undefined);
        const emptyChar = " ", defaultActiveChar = "0";
        for (let i = 0; i <= templateVariablesLength; i++) {
            output[i] = nS[i] ?? emptyChar;
            if (i >= templatePivot && output[i] === emptyChar) {
                output[i] = defaultActiveChar;
            }
        }

        return output;
    }
}

class FloatingPointDriverV2 implements Driver<any, string> {
    transformSource(source: any, template: Template): Array<string> {
        if (template.length === 0) return [];

        const n = Number(source);
        let templatePivot = template.findIndex(token => token.annotations.has("dot"));
        if (templatePivot === -1) templatePivot = template.length - 1;

        const nS = String(n).split("");
        let sourcePivot = nS.findIndex(c => c === ".");
        if (sourcePivot === -1) {
            sourcePivot = nS.length;
            nS.push(".", "0")
        }

        const placeholder = " ";
        const output: string[] = [];
        for (let i = 0; i <= templatePivot; i++) output[i] = placeholder;

        let sourceIdx = sourcePivot;
        let nextSource: () => string;

        // Deal with digits before pivot
        nextSource = () => nS[sourceIdx--];
        for (let templateIdx = templatePivot + 1; templateIdx >= 0; templateIdx--) {
            const token = template[templateIdx];

            if (token === undefined) break;
            else if (token.kind !== "digit") {
                output[templateIdx] = placeholder;
                continue;
            }
            output[templateIdx] = nextSource() ?? " ";
        }

        // Deal with digits after pivot (decimal places)
        sourceIdx = sourcePivot + 1;
        nextSource = () => nS[sourceIdx++];
        for (let templateIdx = templatePivot + 1; templateIdx < template.length; templateIdx++) {
            const token = template[templateIdx];
            if (token.kind !== "digit") {
                output[templateIdx] = placeholder;
                continue;
            }
            output[templateIdx] = nextSource() ?? "0";
        }

        return output;
    }
}

const NodeAdvancedSevenSegmentDisplayStateCtx = React.createContext<NodeAdvancedSevenSegmentDisplayState>({
    tokens: [],
    template: "",
    value: "0",
    reverseFill: false
})

/**
 * Segment coding:
 * |-0-|
 * 1   2
 * |-3-|
 * 4   5
 * |-6-|
 */
const t = true, f = false;
const numberToSegmentActivationDict: { [Key: string]: boolean[] } = {
    //    0 1 2 3 4 5 6
    "0": [t,t,t,f,t,t,t],
    "1": [f,f,t,f,f,t,f],
    "2": [t,f,t,t,t,f,t],
    "3": [t,f,t,t,f,t,t],
    "4": [f,t,t,t,f,t,f],
    "5": [t,t,f,t,f,t,t],
    "6": [t,t,f,t,t,t,t],
    "7": [t,f,t,f,f,t,f],
    "8": [t,t,t,t,t,t,t],
    "9": [t,t,t,t,f,t,t],
    "-": [f,f,f,t,f,f,f],
    "_": [f,f,f,f,f,f,t],
    " ": [f,f,f,f,f,f,f]
}, seg = numberToSegmentActivationDict;
const fallbackSegMapping = [f,f,f,f,f,f,f];

const StyledNodeAdvancedSevenSegmentDisplayDisplay = styled.div`
    padding: 8px 0;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    
    width: 100%;
    justify-content: end;
`;

const NodeAdvancedSevenSegmentDisplayDisplay: FC<{
    node: Node<NodeAdvancedSevenSegmentDisplayState>
}> = props => {
    const node = props.node;
    const cur = node.state.state;

    useEffect(() => {
        node.state.update(prevState => ({
            tokens: new TemplateReader().read(prevState.template)
        }))
    }, [cur.template]);

    useEffect(() => {
        const lastReadState = node.pins.in("s").lastReadState;
        if (lastReadState === undefined) return;
        node.state.update(prevState => ({
            value: newDriver().transformSource(lastReadState, prevState.tokens).join("")
        }));
    }, [cur.tokens]);

    const isReverseFilling = cur.reverseFill;
    let value = String(cur.value);

    // TODO: Think of a better solution
    value = value.replaceAll(".", "");

    // if (isReverseFilling) {
    //     value = value.split("").reverse().join("");
    // }

    const getDigitAt = (i: number): string => {
        return value[i];
    }

    // Counts all digits that correspond to a digit in the value
    let valueMappingDigitCount = isReverseFilling ? value.length - 1 : 0;
    // valueMappingDigitCount = 0;

    const nextDigit = () => {
        if (isReverseFilling) valueMappingDigitCount--;
        else valueMappingDigitCount++;
        // valueMappingDigitCount++
    }

    return (
        <StyledNodeAdvancedSevenSegmentDisplayDisplay>
            <NodeAdvancedSevenSegmentDisplayStateCtx.Provider value={cur} children={
                (() => {

                    return cur.tokens.map((token, i) => {
                        try {
                            return (
                                <Digit
                                    key={i}
                                    token={token}
                                    valueSpelling={getDigitAt(valueMappingDigitCount)}
                                />
                            );
                        } finally {
                            nextDigit()
                        }
                    });

                    let tokens = cur.tokens;
                    if (isReverseFilling) {
                        tokens = ((cur.tokens as any)["toReversed"]() as Token[]);
                    }
                    const mapped = tokens.map((token, i) => {
                        try {
                            return (
                                <Digit
                                    key={i}
                                    token={token}
                                    valueSpelling={getDigitAt(valueMappingDigitCount)}
                                />
                            );
                        } finally {
                            if (token.kind === "digit") nextDigit();
                        }
                    });
                    if (isReverseFilling) mapped.reverse();
                    return mapped;
                })()
            }/>
        </StyledNodeAdvancedSevenSegmentDisplayDisplay>
    );
}

type DigitBaseProps = {
    token: Token,
    valueSpelling: string
}

const Digit: FC<DigitBaseProps> = props => {
    const token = props.token;
    switch (token.kind) {
        case "digit": {
            return (
                <NumericDigit {...props}/>
            );
        }
        case "symbol": {
            return (
                <SymbolDigit {...props}/>
            );
        }
        default: {
            // TODO: Implement
            return (
                <></>
            );
        }
    }
}

const SymbolDigit: FC<DigitBaseProps> = props => {
    const spelling = props.token.spelling;
    return (
        <DescriptiveTypography text={spelling}/>
    );
}

//noinspection CssUnresolvedCustomProperty
const StyledNumericDigit = styled.div`
    --font-scale: 1;
    position: relative;

    width: 16px;
    // width: calc(var(--font-scale) * 8px);
    // width: 8px;
    
    display: flex;
    flex-direction: column;
    align-items: center;

    .two-col-segment-row {
        width: 100%;
        // width: calc(100% + 4px); // TODO: Maybe remove?

        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;

const NumericDigit: FC<DigitBaseProps> = props => {
    const token = props.token;
    const ctx = useContext(NodeAdvancedSevenSegmentDisplayStateCtx);
    const isActivelyShowingAValue = props.valueSpelling !== undefined;
    const segmentCoding = seg[props.valueSpelling] ?? fallbackSegMapping;
    const t = useTriton();
    return (
        <StyledNumericDigit>
            <Segment active={segmentCoding[0]} orientation={Orientation.HORIZONTAL}/>
            <div className={"two-col-segment-row"}>
                <Segment active={segmentCoding[1]} orientation={Orientation.VERTICAL}/>
                <Segment active={segmentCoding[2]} orientation={Orientation.VERTICAL}/>
            </div>
            <Segment active={segmentCoding[3]} orientation={Orientation.HORIZONTAL}/>
            <div className={"two-col-segment-row"}>
                <Segment active={segmentCoding[4]} orientation={Orientation.VERTICAL}/>
                <Segment active={segmentCoding[5]} orientation={Orientation.VERTICAL}/>
            </div>
            <Segment active={segmentCoding[6]} orientation={Orientation.HORIZONTAL}/>

            { token.annotations.has("dot") && (
                <div style={{
                    borderRadius: "50%",
                    position: "absolute",
                    bottom: 0,
                    transform: "translateY(50%)",
                    left: "100%",
                    width: 4,
                    aspectRatio: "1 / 1",
                    transition: "opacity .1s",
                    opacity: isActivelyShowingAValue ? 1 : .1,
                    backgroundColor: t.col("color_primary")
                }}/>
            )}


        </StyledNumericDigit>
    );
}

// noinspection CssUnresolvedCustomProperty,CssReplaceWithShorthandSafely
const StyledSegment = styled.div<{
    props: SegmentProps,
    t: Triton
    fontScale: number
}>`
    --font-scale: ${p => p.fontScale};
    position: relative;
    opacity: .1;
    transition: opacity .1s;
    color: ${p => p.t.col("color_primary")};
    display: flex;
    flex-direction: ${p => p.props.orientation === Orientation.HORIZONTAL ? "row" : "column"};
    align-items: center;
    --tickness: calc(var(--font-scale) * 4px);
    
    // margin: 1px; // TODO: maybe remove?

    .center {
        ${p => p.props.orientation === Orientation.HORIZONTAL ? "height" : "width"}: var(--tickness);
        aspect-ratio: ${p => p.props.orientation === Orientation.HORIZONTAL ? "2 / 1" : "1 / 2"};
        background-color: currentColor;
    }

    &[data-active=true] {
        opacity: 1;
        // box-shadow: 0 0 10px 0 currentColor;
    }

    // TODO: Future Ard: Deal with it... 

    .appendix {
        aspect-ratio: 1 / 1;
        width: calc(var(--font-scale) * 4px);
        position: absolute;

        &.start {
            &.horizontal {
                right: 100%;
                transform: rotate(-90deg);
            }
            &.vertical {
                bottom: 100%;
            }
        }
        &.end {
            &.horizontal {
                left: 100%;
                transform: rotate(90deg);
            }
            &.vertical {
                top: 100%;
                transform: rotate(-180deg);
            }
        }

        &:after {
            position: absolute;
            bottom: 0;
            content: "";
            width: 0;
            height: 0;
            border: ${p => p.fontScale * 2}px solid transparent;
            border-top: 0;
            border-bottom: ${p => p.fontScale * 2}px solid currentColor;
        }

    }
`;

type SegmentProps = {
    active: boolean,
    orientation?: Orientation
}

const Segment: FC<SegmentProps> = props => {
    const t = useTriton();
    const orientationClass = props.orientation === Orientation.VERTICAL ? "vertical" : "horizontal";

    return (
        <StyledSegment props={props} t={t} data-active={props.active} fontScale={1}>
            <div className={`appendix start ${orientationClass}`}></div>
            <div className={`center`}></div>
            <div className={`appendix end ${orientationClass}`}></div>
        </StyledSegment>
    );
}

