import {PinListener} from "./PinListener";
import {NodeDependent} from "./NodeDependent";
import {Node} from "./Node";
import {NodeEventTypes} from "./NodeEventTypes";
import {NodeEvent} from "./NodeEvent";
import {StandaloneObservable} from "../../ardai/webapi/pubsub/StandaloneObservable";
import {v4} from "uuid";
import {NodeCanvasBackendEventTypes} from "../frontend/NodeCanvasBackendEventTypes";
import {PinBank} from "./PinBank";
import {DefaultTypeNames} from "./typing/defaults/DefaultTypeNames";
import {PinStateStatusType} from "./PinStateStatusType";
import {StatusRegister} from "./StatusRegister";
import {FQPinId, mkFQPinId} from "./FQPinId";
import {PinInputTransformer} from "./PinInputTransformer";
import {PinExplorerEphemeralAPI} from "../frontend/PinExplorerEphemeralAPI";

export enum PinType {
    DATA,
    INSTANCE
}

export enum PinMode {
    IN, OUT
}

// noinspection SpellCheckingInspection
export type PinEphemerals = Partial<{
    portExplorerAPI: PinExplorerEphemeralAPI
}>

export type PinConfig = {
    id?: string,
    label?: string,
    mode?: PinMode,
    type?: PinType,
    parentBank: PinBank<any>,
    dye?: string // TODO: Move to PinState

    displayName?: string,
    flags: Array<string>,

    /**
     * Only takes affect if "type" is "DATA"
     */
    typeName?: string,
}

export type PinState = {
    status: PinStateStatusType
}

export class Pin<Type = any, ValueType = any> extends NodeDependent {

    public observer: StandaloneObservable<NodeEventTypes, NodeEvent> = new StandaloneObservable<NodeEventTypes, NodeEvent>();

    public readonly config: PinConfig;

    public readonly statusRegister: StatusRegister = new StatusRegister(this);

    public inputConnections: Array<Pin<Type>> = [];

    private _inputTransformer?: PinInputTransformer<Type>;

    // noinspection SpellCheckingInspection
    public readonly ephemerals: PinEphemerals = {};

    public state: PinState = {
        status: {}
    };

    constructor(
        node: Node,
        parentBank: PinBank<any>,
        config: Partial<PinConfig> = {},
        public outputConnections: Array<Pin<Type>> = [],
        public readonly listeners: Array<PinListener<Type>> = [],
        public lastReadState: Type | undefined = undefined,
        public lastWriteState: Type | undefined = undefined,
        public value: ValueType | undefined = undefined
    ) {
        super(node);
        this.config = {
            id: v4(),
            typeName: DefaultTypeNames.typeNotSpecified,
            type: PinType.DATA,
            flags: [],
            parentBank,
            ...config
        };
        this.observer.relayGlobally(node.observer);
    }

    public get id(): string {
        return this.config.id!;
    }

    public get label(): string {
        return this.config.label!;
    }

    public get mode(): PinMode {
        return this.config.mode ?? PinMode.IN;
    }

    public get status(): StatusRegister {
        return this.statusRegister;
    }

    public setTypeName(fullyQualifiedTypeName: string) {
        this.updateDefinition(pin => {
            pin.config.typeName = fullyQualifiedTypeName;
        });
    }

    public setDisplayName(displayName: string): this {
        this.updateDefinition(pin => {
            pin.config.displayName = displayName;
        });
        return this;
    }

    set inputTransformer(value: PinInputTransformer<Type>) {
        this._inputTransformer = value;
    }

    public disconnect(pin: Pin<Type>): this {
        this.outputConnections = this.outputConnections.filter(con => con !== pin);
        pin.onConnectionToThisPinCut(this);
        return this;
    }

    public hasOutputConnectionTo(pin: Pin<Type>): boolean {
        return this.outputConnections.find(connectedPin => connectedPin.id === pin.id) !== undefined;
    }

    /**
     * Establishes a connection from this pin (source) to the parameter pin (target)
     *
     * @param pin target pin
     */
    public connect(pin: Pin<Type>): this {

        if (this.hasOutputConnectionTo(pin)) {
            console.error("Already connected");
            return this;
        }

        this.outputConnections.push(pin);

        // this.node.observer.notify(NodeEventTypes.GRAPH_UPDATE, new NodeEvent());
        // this.node.backend?.events.notify(NodeCanvasBackendEventTypes.GRAPH_UPDATE, undefined);

        pin.onConnectionToThisPinEstablished(this);

        return this;
    }

    /**
     * Disconnects all outbound & inbound pin connections
     */
    public cutLoose() {
        // Cut all outbound connections
        this.outputConnections.filter(outConPin => {
            this.disconnect(outConPin);
        });
        // Cut all inbound connections
        this.inputConnections.forEach(inConPin => {
            inConPin.disconnect(this);
        })
    }

    public write(data: Type) {
        this.lastWriteState = data;
        this.outputConnections.forEach(pin => {
            try {
                pin.onRead(data);
            } catch (e) {
                console.error("Error while writing to pin")
                console.error(e);
            }
        });
        this.observer.notify(NodeEventTypes.PIN_WRITE, new NodeEvent());
    }

    public onConnectionToThisPinEstablished(fromPin: Pin) {
        this.inputConnections.push(fromPin);
        const bank = this.config.parentBank;
        if (bank === undefined) return;
        bank.listeners.forEach(l => {
            l.onPinNewInboundConnection?.(this, fromPin);
        });
    }

    public onConnectionToThisPinCut(fromPin: Pin) {
        this.inputConnections = this.inputConnections.filter(iC => iC !== fromPin);
        const bank = this.config.parentBank;
        if (bank === undefined) return;
        bank.listeners.forEach(l => {
            l.onPinCutInboundConnection?.(this, fromPin);
        });
    }

    public setLabel(label: string) {
        this.updateVisuals(pin => {
            pin.config.label = label;
        });
    }

    private updateDefinition(kernel: (pin: this) => void) {
        kernel(this);
        this.observer.notify(NodeEventTypes.PIN_DEFINITION_UPDATED, new NodeEvent());
    }

    private updateVisuals(kernel: (pin: this) => void) {
        kernel(this);
        this.observer.notify(NodeEventTypes.PIN_VISUALS_UPDATED, new NodeEvent());
    }

    /**
     * TODO: Check if observer notify is necessary -> before === new -> no notify
     *
     * @param kernel
     * @private
     */
    public updateState(kernel: (state: PinState, pin: this) => void | PinState) {
        const res = kernel(this.state, this);
        if (res !== undefined) {
            this.state = res as PinState;
        }
        this.observer.notify(NodeEventTypes.PIN_STATE_UPDATED, new NodeEvent());
    }

    private transformReadInput(raw: any): Type {
        if (this._inputTransformer === undefined) return raw;
        return this._inputTransformer(raw);
    }

    public onRead(data: Type) {
        data = this.transformReadInput(data);

        this.lastReadState = data;
        this.listeners.forEach(listener => {
            listener.read.call(this, data);
        });
        this.config.parentBank?.onPinRead(this, data);
        this.observer.notify(NodeEventTypes.PIN_READ, new NodeEvent());
    }

    public attach(listener: PinListener<Type>): this {
        this.listeners.push(listener);
        return this;
    }

    public attachOnRead(readListener: (data: Type) => void): this {
        this.attach({
            read: readListener
        });
        return this;
    }

    /**
     * TODO: Implement
     * TODO: Write docs
     */
    public destroy() {
        throw new Error("method not implemented yet");
        // Cut loose

        // Remove pin from pin-bank
    }

    public get hasInputConnections(): boolean {
        return this.inputConnections.length > 0;
    }

    public get canWrite(): boolean {
        return this.mode === PinMode.OUT;
    }

    public get isInstancePin(): boolean {
        return this.config.type === PinType.INSTANCE
    }

    public get isDataPin(): boolean {
        return this.config.type === PinType.DATA;
    }

    public get fqId(): FQPinId {
        const bankFqId = this.config.parentBank.fqId;
        return mkFQPinId(this.node.fqId.node, bankFqId.bank, this.config.id!, bankFqId.bankLabel, this.label)
    }
}
