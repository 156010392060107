import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {DefaultCharacterSymbols} from "../../DefaultCharacterSymbols";

export const NodeMemorizingAdder: NodeSetupInfo = {
    classname: "arithmetic.memorizing-adder",
    label: "NodeMemorizingAdder",
    parameterConfig: [],
    factory(parameters: Map<string, any>): Node<NodeMemorizingAdderState> {
        return new NodeMemorizingAdderClass;
    }
}

export type NodeMemorizingAdderState = {
    value: number
}

export class NodeMemorizingAdderClass extends Node<NodeMemorizingAdderState> {

    constructor() {
        super({
            id: v4(),
            label: "add (memo)",
            classname: "arithmetic.memorizing-adder",
            defInPins: [DefaultCharacterSymbols.clockPinKey],
            state: {
                value: 0
            }
        });
    }

    y = this.pins.out("y");

    offset = this.pins.in("+");

    clock = this.pins.helpers.clock().attachOnRead(() => {
        this.calcNewValue();
    })

    private calcNewValue() {
        const offset = Number(this.offset.lastReadState ?? 0);
        const newValue = this.state.state.value + offset;
        this.state.update({
            value: newValue
        });
        this.y.write(newValue);
    }
}
