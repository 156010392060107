// noinspection all
import {WithLifecycle} from "./WithLifecycle";

export abstract class Configurable<
    ConfigurationType = {},
    RequiredType extends keyof ConfigurationType = never,
> extends WithLifecycle {
    protected readonly configOverwrites: Partial<ConfigurationType> = {};

    constructor(
        protected readonly staticConfig:
            // Required<Pick<ConfigurationType, RequiredType>> &
            Pick<ConfigurationType, RequiredType> &
            Partial<ConfigurationType>
    ) {
        super();
    }

    public get config(): Readonly<ConfigurationType> {
        return {
            ...this.defaultConfig,
            ...this.staticConfig,
            ...this.configOverwrites,
        } as ConfigurationType;
    }

    protected abstract get defaultConfig(): Readonly<Omit<ConfigurationType, RequiredType>>;
}

