import {FC, useRef} from "react";
import {Pin, PinMode} from "../backend/Pin";
import {getPinHtmlID} from "./NodeFrontendUtils";
import Draggable from "react-draggable";
import {useNodeCanvasBackend} from "./NodeCanvasBackend";
import {NodeCanvasBackendEventTypes} from "./NodeCanvasBackendEventTypes";
import {useAdvancedState} from "../../ardai/hooks/StaticStateHook";
import styled from "styled-components";
import {Triton} from "../../triton/Triton";
import {useTriton} from "../../triton/TritonHooks";
import {StdWire} from "./components/wires/StdWire";
import {useForceRenderFunc} from "../../ForceRerenderHook";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {motion} from "framer-motion";
import {DefaultCharacterSymbols} from "./DefaultCharacterSymbols";

const StyledNodePinExplorer = styled.div<{
    t: Triton,
    s: NodePinExplorerState
}>`
  .explorer-pin-visualization {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 300;
    aspect-ratio: 1 / 1;
    width: 10px;
    border-radius: 50%;
    border: 1px solid ${p => false ? p.t.col("color_primary") : p.t.col("fg_muted")};
    background-color: ${p => false ? p.t.colObj("color_primary").withAlpha(.1).css() : p.t.colObj("fg_muted").withAlpha(.1).css()};
    
    &[data-active=false] {
      opacity: 0;
    }
  }
`;

export type NodePinExplorerState = {
    active: boolean,
    isOnTarget: boolean,
    targetPin: Pin | undefined,
    explorerPositionDelta: {
        x: number,
        y: number
    }
}

export type NodePinExplorerProps = {
    pin: Pin
}

export const NodePinExplorer: FC<NodePinExplorerProps> = props => {
    const pin = props.pin;
    const portClass = "port";
    const backend = useNodeCanvasBackend();
    const t = useTriton();

    const forceRender = useForceRenderFunc();

    const [state, ctx] = useAdvancedState<NodePinExplorerState>({
        staticMode: false,
        initial: {
            active: false,
            isOnTarget: false,
            targetPin: undefined,
            explorerPositionDelta: {
                x: 0,
                y: 0
            }
        }
    }).stateWithCtx;

    const resetExplorer = () => {
        ctx.update({
            active: false,
            explorerPositionDelta: {
                x: 0,
                y: 0
            }
        });
    }

    const srcRef = useRef<HTMLDivElement>(null);
    const dstRef = useRef<HTMLDivElement>(null);

    const pinDiameter = 10;
    const pinRadius = pinDiameter / 2;

    const [dState, dCtx] = useAdvancedState<{ [Key: string]: string | undefined | null }>({
        staticMode: false,
        initial: {}
    }).stateWithCtx;

    pin.ephemerals.portExplorerAPI = {
        setExploring(x: number, y: number) {
            ctx.update({
                active: true,
                explorerPositionDelta: {
                    x, y
                }
            });
        }
    };

    return (
        <StyledNodePinExplorer s={state} t={t} ref={srcRef}>

            <Draggable
                enableUserSelectHack={true}
                position={state.explorerPositionDelta}
                onStart={e => {
                    e.stopPropagation();
                    ctx.update({
                        active: true
                    });
                }}
                onDrag={(e, data) => {

                    

                    // TODO: Remove
                    dCtx.setState(() => ({}))

                    ctx.update(prevState => ({
                        explorerPositionDelta: {
                            x: prevState.explorerPositionDelta.x + data.deltaX,
                            y: prevState.explorerPositionDelta.y + data.deltaY
                        }
                    }));

                    const portExplorer = document.getElementById(`${getPinHtmlID(pin)}-port-explorer`)
                    const pERect = portExplorer!.getBoundingClientRect();

                    const probeX = pERect.left
                    const probeY = pERect.top

                    // const discoveredElement = document.elementFromPoint(
                    //     pERect.left,
                    //     pERect.top
                    // );

                    const elementsAtPosition = document.elementsFromPoint(
                        pERect.left,
                        pERect.top
                    );
                    const discoveredElement = elementsAtPosition.find(elem => elem.classList.contains("port"));

                    let isOnTarget = false;
                    let targetPin: Pin | undefined;
                    explorerTargetIdentificationLogic: {
                        if (discoveredElement === undefined) break explorerTargetIdentificationLogic;
                        if (!discoveredElement.classList.contains(portClass)) break explorerTargetIdentificationLogic;
                        const targetNodeIdAttr = discoveredElement.attributes.getNamedItem("data-node-id")
                        const targetPinIdAttr = discoveredElement.attributes.getNamedItem("data-pin-id")
                        const targetNode = backend.environment.getNodeByID(targetNodeIdAttr!.value);
                        const pinId = targetPinIdAttr!.value;
                        targetPin = targetNode.pins.getPinById(pinId);
                        if (targetPin === undefined) break explorerTargetIdentificationLogic;
                        if (pin === targetPin) break explorerTargetIdentificationLogic;
                        if (targetPin.mode !== PinMode.IN) break explorerTargetIdentificationLogic;
                        isOnTarget = true;

                        // TODO: Remove
                        dCtx.update({
                            hasPortExplorer: portExplorer === undefined ? "no" : "yes",
                            portExplorerID: portExplorer?.id,
                            targetNodeIdAttr: targetNodeIdAttr?.value,
                            targetPinIdAttr: targetPinIdAttr?.value,
                            pinId,
                            x: String(probeX),
                            y: String(probeY)
                        });
                    }
                    ctx.update(prevState => ({
                        isOnTarget,
                        targetPin
                    }));
                    dCtx.update({
                        discoveredElement: discoveredElement?.className
                    });
                }}
                onStop={(e, data) => {
                    const portExplorer = document.getElementById(`${getPinHtmlID(pin)}-port-explorer`)
                    const pERect = portExplorer!.getBoundingClientRect();

                    // const discoveredElement = document.elementFromPoint(pERect.x, pERect.y);

                    const elementsAtPosition = document.elementsFromPoint(
                        pERect.left,
                        pERect.top
                    );
                    const discoveredElement = elementsAtPosition.find(elem => elem.classList.contains("port"));


                    connectionLogic: if (discoveredElement?.classList.contains(portClass)) {
                        const targetNodeIdAttr = discoveredElement.attributes.getNamedItem("data-node-id")
                        const targetPinIdAttr = discoveredElement.attributes.getNamedItem("data-pin-id")
                        const targetNode = backend.environment.getNodeByID(targetNodeIdAttr!.value);
                        const pinId = targetPinIdAttr!.value;
                        const targetPin = targetNode.pins.getPinById(pinId);
                        if (targetPin === undefined) break connectionLogic; // TODO: Check if auto-creation is allowed
                        if (pin === targetPin) break connectionLogic; // TODO: Check if self-connecting is allowed
                        if (targetPin.mode !== PinMode.IN) break connectionLogic;
                        pin.connect(targetPin);
                        backend.events.notify(NodeCanvasBackendEventTypes.GRAPH_UPDATE, undefined);
                    }
                    resetExplorer();

                    // TODO: Remove
                    dCtx.setState(() => ({}))
                }}
                children={
                    <div
                        ref={dstRef}
                        className={"explorer-pin-visualization"}
                        data-active={state.active}
                        id={`${getPinHtmlID(pin)}-port-explorer`}
                        onClick={e => {
                            e.stopPropagation();
                        }}
                        children={
                            <div style={{
                                position: "relative"
                            }}>
                                { state.active && (
                                    <motion.div style={{
                                        zIndex: 2000,
                                        // borderWidth: 1,
                                        // borderStyle: "solid",
                                        // borderColor: ctx.state.isOnTarget ? t.col("color_primary") : t.col("bg_modal"),
                                        backgroundColor: t.col("bg_modal"),
                                        padding: 8,
                                        borderRadius: 8,
                                        position: "absolute",
                                        left: "50%",
                                        transform: "translateX(-50%) translateY(-12px)",
                                        bottom: "100%",
                                        pointerEvents: "none",
                                        display: "flex",
                                        flexDirection: "column",
                                        x: "-50%"
                                    }}
                                    initial={{
                                        y: "100%",
                                        opacity: 0
                                    }}
                                    animate={{
                                        y: -16,
                                        opacity: 1
                                    }}
                                    >
                                        <DescriptiveTypography
                                            text={`valid: ${ctx.state.isOnTarget ? "yes" : "no"}`}
                                            whiteSpaceNoWrap
                                        />
                                        <DescriptiveTypography
                                            text={`pin below: ${ctx.state.targetPin?.id !== undefined ? "yes" : "no"}`}
                                            whiteSpaceNoWrap
                                        />

                                        { Object.entries(dState).map(kv => {
                                            return (
                                                <DescriptiveTypography
                                                    key={kv[0]}
                                                    text={`${kv[0]}: ${kv[1] ?? DefaultCharacterSymbols.placeholder}`}
                                                    whiteSpaceNoWrap
                                                />
                                            );
                                        }) }

                                    </motion.div>
                                )}
                            </div>
                        }
                    />
                }
            />

            <div style={{
                pointerEvents: "none",
                position: "absolute",
                top: 0,
                left: 0
            }}>
                <StdWire
                    points={[
                        { x: pinRadius, y: pinRadius },
                        { x: state.explorerPositionDelta.x + pinRadius, y: state.explorerPositionDelta.y + pinRadius }
                    ]}
                    lineStyle={{
                        stroke: state.isOnTarget ? t.col("color_primary") : "currentcolor"
                    }}
                />
            </div>
        </StyledNodePinExplorer>
    );
}

