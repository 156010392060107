import React, {ReactNode} from "react";
import styled from "styled-components";
import {useTriton} from "../../../../triton/TritonHooks";
import {Triton} from "../../../../triton/Triton";
import {IndicatorUtils} from "./utils/IndicatorUtils";
import {Tooltip} from "react-tooltip";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {DefaultCharacterSymbols} from "../../DefaultCharacterSymbols";

const StyledIndicatorIcon = styled.div<{
    t: Triton,
    iconColor: string,
    colorTransitionDurationMS: number
}>`
  color: ${p => p.iconColor};
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg {
    // font-size: 16px;
    fill: currentcolor;
    display: inline-block;
    user-select: none;
    flex-shrink: 0;
    transition: color ${p => p.colorTransitionDurationMS}ms ease 0s;
    // width: 1em;
    aspect-ratio: 1 / 1;
    font-size: 14px;
  }
`;

export type IndicatorIconProps = {
    mappingKey: number,
    colorMapping: IndicatorUtils.NumericMapping<string>,
    iconMapping: IndicatorUtils.NumericMapping<ReactNode>,
    colorTransitionDurationMS?: number,
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

/**
 * TODO: Make sure 'colorMapping' & 'iconMapping' aren't causing any issues -> related to react should-update logic
 */
export const IndicatorIcon = (props: IndicatorIconProps) => {
    const {mappingKey, colorMapping, iconMapping} = props;
    const colorTransitionDurationMS = props.colorTransitionDurationMS ?? 5e2
    const t = useTriton();
    const color = colorMapping.get(mappingKey) ?? t.col("fg_muted")
    let icon = iconMapping.get(mappingKey);
    const iconType = typeof icon;
    if (iconType === "string" || iconType === "number") {
        icon = <DescriptiveTypography
            text={icon}
            baseProps={{
                onClick: props.onClick
            }}
            style={{
                transition: `color ${colorTransitionDurationMS}ms ease 0s`,
                color
            }}
        />
    }

    return (
        <StyledIndicatorIcon
            colorTransitionDurationMS={colorTransitionDurationMS}
            iconColor={color ?? t.col("fg_muted")}
            onClick={props.onClick}
            t={t}
            children={icon ?? (
                <DescriptiveTypography text={DefaultCharacterSymbols.placeholder} noSelect style={{
                    color
                }}/>
            )}
        />
    );
};
