import {IRenderTarget} from "../interfaces/IRenderTarget";
import React, {ReactNode} from "react";
import {RenderContext} from "./RenderContext";
import {Configurable} from "./Configurable";
import {DataObject} from "../data/DataObject";
import {RiggingAnchorRenderer} from "./RiggingAnchorRenderer";
import {RiggingAnchor} from "./RiggingAnchor";

export type ObjectConfig = {
    data: DataObject
}

export class Object extends Configurable<ObjectConfig, "data"> implements IRenderTarget<ReactNode> {

    render(context: RenderContext): React.ReactNode {
        const debugCenterBoxDimension = .05 * 1.25;

        return (
            <mesh
                scale={1}
                position={[-.2, .35, 0]}
                rotation={[0, -.2 * Math.PI, 0]}
            >
                {/*
                <boxGeometry args={[debugCenterBoxDimension, debugCenterBoxDimension, debugCenterBoxDimension]}/>
                <meshStandardMaterial color={'red'} wireframe/>
                */}

                {
                    <RiggingAnchorRenderer anchor={new RiggingAnchor({
                        data: this.config.data.root!
                    })}/>
                }
            </mesh>
        );
    }

    protected get defaultConfig(): Readonly<Omit<ObjectConfig, "data">> {
        return {};
    }
}
