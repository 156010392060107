import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {NodeSetupInfo} from "../../NodeSetupInfo";
import {DefaultCharacterSymbols} from "../../DefaultCharacterSymbols";
import {NodeImageBufferClass} from "./NodeImageBuffer";
import {Pixel} from "./data/Pixel";

export const NodePixelSetter: NodeSetupInfo = {
    classname: "images.pixel-setter",
    label: "NodePixelSetter",
    parameterConfig: [],
    factory(parameters: Map<string, any>): Node {
        return new NodePixelGetterClass();
    }
}

export class NodePixelGetterClass extends Node {
    constructor() {
        super({
            id: v4(),
            label: "img pixel set",
            classname: "images.pixel-setter"
        });
    }

    c = this.pins.in(DefaultCharacterSymbols.clockPinKey).attachOnRead(() => {
        const x = Number(this.x.lastReadState ?? 0);
        const y = Number(this.y.lastReadState ?? 0);
        const pixel: Pixel = this.p.lastReadState;
        const buf = this.buf.inputConnections[0].value as NodeImageBufferClass;
        buf.setPixel(x, y, pixel);
    });

    buf = this.pins.in("#buf");

    x = this.pins.in("x");

    y = this.pins.in("y");

    p = this.pins.in("p");
}