import {Configurable} from "./Configurable";
import {DataRiggingAnchor} from "../data/DataRiggingAnchor";
import {Omit} from "react-beautiful-dnd";

export class RiggingAnchor extends Configurable<{
    data: DataRiggingAnchor
}, "data"> {
    protected get defaultConfig(): Readonly<Omit<{ data: DataRiggingAnchor }, "data">> {
        return {};
    }
}
