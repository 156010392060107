import {PinLayout} from "../PinLayout";
import {DefaultCharacterSymbols} from "../../frontend/DefaultCharacterSymbols";
import {Pin} from "../Pin";

export class PinLayoutHelpers {

    constructor(
        private readonly layout: PinLayout
    ) {}

    public clock(): Pin {
        return this.layout.in(DefaultCharacterSymbols.clockPinKey).setDisplayName("clock signal");
    }
}
