import {FC} from "react";
import {LayoutPanelDefinition} from "./LayoutPanelDefinition";
import {layoutAPIContextBridge} from "./LayoutAPIContext";
import {ReactMaster} from "../../../ReactAPIBridgeUtils";

export type LayoutProps = {
    panels: {
        [K: string]: LayoutPanelDefinition
    }
}

export const Layout: FC<LayoutProps> = props => {
    return (
        <ReactMaster fx={layoutAPIContextBridge}>

        </ReactMaster>
    );
}
