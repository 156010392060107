import {Configurable} from "./Configurable";
import {DataRiggingBone} from "../data/DataRiggingBone";
import {Omit} from "react-beautiful-dnd";

export class RiggingBone extends Configurable<{
    data: DataRiggingBone
}, "data"> {
    protected get defaultConfig(): Readonly<Omit<{ data: DataRiggingBone }, "data">> {
        return {};
    }
}
