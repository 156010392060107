import {ReactNode} from "react";
import {createBridge, ReactBackend} from "../../ReactAPIBridgeUtils";
import {v4} from "uuid";

/**
 *
 */
export namespace NotificationPrototype {

    export enum NotificationLevel {
        TRACE,
        DEBUG,
        NORMAL,
        WARNING,
        ERROR
    }

    export type Notification = {
        id: string,
        title?: string,
        body?: Array<any>,
        level: NotificationLevel,
        attachments?: Array<any>,
        icon?: ReactNode,
        timestamp?: number,
        customRenderer?: () => ReactNode
    }

    export interface NotificationContext {
        destroy(): void
    }

    export class NotificationCenter extends ReactBackend<NotificationCenterState> {

        appendNotification = (notification: Notification): this => {
            this.setState(prevState => ({
                ...prevState,
                notifications: [notification, ...prevState.notifications].splice(0, 1e2)
            }))
            return this;
        }

        _send = (...data: any[]) => {
            this.appendNotification({
                id: v4(),
                level: NotificationLevel.NORMAL,
                body: data
            });
        }

        _warn = (...data: any[]) => {
            this.appendNotification({
                id: v4(),
                level: NotificationLevel.WARNING,
                body: data
            });
        }

        _getNotificationCtx = (notificationId: string): NotificationContext => {
            const target = this.state.notifications.find(notification => notification.id === notificationId);
            if (target === undefined) throw new Error;
            return {
                destroy: () => {
                    this.setState(prevState => ({
                        ...prevState,
                        notifications: prevState.notifications.filter(notification => notification.id !== target.id)
                    }))
                }
            }
        }
    }

    export type NotificationCenterState = {
        notifications: Array<Notification>
    }

    export const notificationCenterReactBridge = createBridge<NotificationCenterState, NotificationCenter>({
        backend: () => new NotificationPrototype.NotificationCenter(),
        state: {
            notifications: []
        }
    });

    export const useNotifications = (): NotificationCenter => {
        return NotificationPrototype.notificationCenterReactBridge.useBackend();
    }
}
