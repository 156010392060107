import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {NodeSetupInfo} from "../../NodeSetupInfo";
import {DefaultCharacterSymbols} from "../../DefaultCharacterSymbols";
import {NodeImageBufferClass} from "./NodeImageBuffer";

export const NodePixelGetter: NodeSetupInfo = {
    classname: "images.pixel-getter",
    label: "NodePixelGetter",
    parameterConfig: [],
    factory(parameters: Map<string, any>): Node {
        return new NodePixelGetterClass();
    }
}

export class NodePixelGetterClass extends Node {

    constructor() {
        super({
            id: v4(),
            label: "img pixel get",
            classname: "images.pixel-getter"
        });
    }

    c = this.pins.in(DefaultCharacterSymbols.clockPinKey).attachOnRead(() => {
        const x = Number(this.x.lastReadState ?? 0);
        const y = Number(this.y.lastReadState ?? 0);
        const buf = this.buf.inputConnections[0].value as NodeImageBufferClass;
        this.output.write(buf.getPixel(x, y));
    });

    buf = this.pins.in("#buf");

    x = this.pins.in("x");

    y = this.pins.in("y");

    output = this.pins.out("p");
}
