import {FC} from "react";
import {Layout} from "../Layout";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";

export const TestLayout: FC = props => {
    return (
        <Layout panels={{
            a: {
                render() {
                    return <DescriptiveTypography text={"a"}/>
                }
            },
            b: {
                render() {
                    return <DescriptiveTypography text={"b"}/>
                }
            },
            c: {
                render() {
                    return <DescriptiveTypography text={"c"}/>
                }
            }
        }}/>
    );
}
