import {CSSProperties, FC, useEffect, useRef} from "react";
import {Pin} from "../backend/Pin";
import {getPinHtmlID, getPinWireHtmlID} from "./NodeFrontendUtils";
import {useTriton} from "../../triton/TritonHooks";
import styled from "styled-components";
import {useStaticState} from "../../ardai/hooks/StaticStateHook";
import {WireInfoCard} from "./WireInfoCard";
import {useForceRenderFunc} from "../../ForceRerenderHook";
import {Triton} from "../../triton/Triton";
import {parsePath, roundCommands, roundCorners} from "svg-round-corners";
import {NodeCanvasBackendEventTypes} from "./NodeCanvasBackendEventTypes";
import {useNodeCanvasBackend} from "./NodeCanvasBackend";

const StyledPortWireDisplay = styled.div<{
    t: Triton,
    isWireRenderingSelectedVisualization: boolean
}>`
  
  svg {
    color: ${p => p.isWireRenderingSelectedVisualization ? 
      p.t.col("color_secondary") : 
      p.t.col("fg_muted")
    }
  }
  
  svg[data-wire-is-signalling=true] {
    color: ${p => p.t.col("color_secondary")}
    // color: ${p => p.t.col("color_primary")}
  }
  
  svg[data-wire-is-highlighted=true] {
    // color: ${p => p.t.colObj("color_secondary").withAlpha(.5).css()};
    color: ${p => p.t.col("color_secondary")}
  }
  
  .a:hover {
    // stroke: rebeccapurple !important;
  }
`;

export type PortWireDisplayState = {
    selected: boolean
}

export type PortWireDisplayRenderConfig = {
    enableSelectedLineVisualization?: boolean
}

export type PortWireDisplayProps = {
    originPort: Pin,
    destPort: Pin,
    renderConfig?: Partial<PortWireDisplayRenderConfig>
}

export const PortWireDisplay: FC<PortWireDisplayProps> = props => {
    const t = useTriton();
    const backend = useNodeCanvasBackend();

    const [state, ctx] = useStaticState<PortWireDisplayState>({
        id: "PortWireDisplay-local",
        staticMode: false,
        initial: {
            selected: false
        }
    }).stateWithCtx;

    const forceRender = useForceRenderFunc();
    useEffect(() => props.originPort.observer.observeGlobally().on(forceRender).destructor, []);
    useEffect(() => props.destPort.observer.observeGlobally().on(forceRender).destructor, []);

    const getHtml = (pin: Pin) => document.getElementById(`${getPinHtmlID(pin)}-port`);
    const portA = getHtml(props.originPort), portB = getHtml(props.destPort);
    if (portA == null || portB == null) return <></>;
    const rectA = portA!.getBoundingClientRect(), rectB = portB!.getBoundingClientRect();
    const aX = rectA.x, aY = rectA.y;
    const bX = rectB.x, bY = rectB.y;

    let doesAPortHaveAnError = false;
    if (props.originPort.statusRegister.defaults.isError) doesAPortHaveAnError = true;
    if (props.destPort.statusRegister.defaults.isError) doesAPortHaveAnError = true;

    const renderConfig: PortWireDisplayRenderConfig = {
        enableSelectedLineVisualization: true,
        ...props.renderConfig
    };

    type Point = { x: number, y: number };
    const points: Array<Point> = [];
    // original
    // points.push({ x: aX + .5 * rectA.width + 5, y: aY + .5 * rectA.height });
    // points.push({ x: (aX + .5 * rectA.width) + 15, y: aY + .5 * rectB.height });
    // points.push({ x: (aX + .5 * rectA.width) + 15, y: bY + .5 * rectB.height });
    // points.push({ x: (bX + .5 * rectB.width) - 5, y: bY + .5 * rectB.height });

    // variable design
    // points.push({ x: aX + .5 * rectA.width + 5, y: aY + .5 * rectA.height });
    // points.push({ x: (aX + .5 * rectA.width) + 15, y: aY + .5 * rectB.height });
    // points.push({ x: (bX + .5 * rectB.width) - 15, y: bY + .5 * rectB.height });
    // points.push({ x: (bX + .5 * rectB.width) - 5, y: bY + .5 * rectB.height });

    if (aX + 30 < bX) {
        // a--?--b
        const horizontalHalfwayPoint = (bX - aX) / 2;
        points.push({ x: aX + .5 * rectA.width + 5, y: aY + .5 * rectA.height });
        points.push({ x: (aX + .5 * rectA.width) + horizontalHalfwayPoint, y: aY + .5 * rectB.height });
        points.push({ x: (aX + .5 * rectA.width) + horizontalHalfwayPoint, y: bY + .5 * rectB.height });
        points.push({ x: (bX + .5 * rectB.width) - 5, y: bY + .5 * rectB.height });
    } else {
        // b--?--a
        const verticalHalfwayPoint = (bY - aY) / 2;
        points.push({ x: aX + .5 * rectA.width + 5, y: aY + .5 * rectA.height });
        points.push({ x: (aX + .5 * rectA.width) + 15, y: aY + .5 * rectB.height });
        points.push({ x: (aX + .5 * rectA.width) + 15, y: aY + verticalHalfwayPoint });
        points.push({ x: (bX + .5 * rectA.width) - 15, y: aY + verticalHalfwayPoint });

        points.push({ x: (bX + .5 * rectB.width) - 15, y: bY + .5 * rectB.height });
        points.push({ x: (bX + .5 * rectB.width) - 5, y: bY + .5 * rectB.height });
    }

    const wireCenter: Point = {
        x: aX + 10 + 16,
        y: aY + .5 * rectB.height
    }

    const canvasNode = document.getElementById("_node-canvas");
    const canvasRect = canvasNode!.getBoundingClientRect();

    const isWireRenderingSelectedVisualization = (state.selected && renderConfig.enableSelectedLineVisualization) ?? false;

    return (
        <StyledPortWireDisplay
            t={t}
            isWireRenderingSelectedVisualization={isWireRenderingSelectedVisualization}
            style={{
                position: "relative",
                top: -canvasRect.y,
                left: -canvasRect.x
            }}
        >
            { state.selected && (
                <div style={{
                    padding: 0,
                    position: "absolute",
                    top: wireCenter.y,
                    left: wireCenter.x,
                    transform: "translateY(-50%)",
                    zIndex: 1000
                }} children={
                    <WireInfoCard originPort={props.originPort} destPort={props.destPort} closeInfo={() => {
                        ctx.update({
                            selected: false
                        })
                    }}/>
                }/>
            ) }

            <svg id={getPinWireHtmlID(props.originPort, props.destPort)} xmlns="http://www.w3.org/2000/svg" style={{
                pointerEvents: "none",
                // scale: 1 / .5, // TODO: Implement wire scaling according to canvas scale
                width: "100vw",
                height: "100vh",
                position: "absolute",
                overflow: "visible",
                left: 0,
                top: 0,
                zIndex: 1,
                color: doesAPortHaveAnError ? "crimson" : (
                    // (state.selected && renderConfig.enableSelectedLineVisualization) ?
                    //     t.col("color_secondary") :
                    //     t.col("fg_muted")
                    undefined
                )
            }}>

                <path
                    d={(() => {
                        const path = points.map(p => `${p.x},${p.y}`).join(" ");
                        return roundCorners(`M${points[0].x},${points[0].y} ${path}`, 10, 2).path;
                    })()}
                    strokeLinejoin={"round"}
                    strokeDasharray={doesAPortHaveAnError ? 5 : 0}
                    style={{
                        overflow: "visible",
                        fill: "none",
                        stroke: "currentcolor",
                        strokeWidth: 1,
                        // pointerEvents: "stroke" // TODO: Check: Why not "none"?

                        pointerEvents: "none"
                    }}
                />

                {/* TODO: Make draggable */}
                <polyline
                    className={"a"}
                    points={points.map(p => `${p.x},${p.y}`).join(" ")}
                    strokeLinejoin={"round"}
                    onDragStart={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        console.log(e)
                        props.originPort.disconnect(props.originPort);


                        // TODO: Get origin port pin explorer and set x,y to current mouse pos + "isExploring = true"
                        props.originPort.ephemerals.portExplorerAPI?.setExploring(0, 0);
                    }}
                    style={{
                        fill: "none",
                        stroke: "transparent",
                        strokeWidth: 10,
                        pointerEvents: "stroke",
                        cursor: "pointer"
                    }}
                    onMouseDown={e => {
                        if (e.ctrlKey) {
                            props.originPort.disconnect(props.destPort);
                            const x = e.clientX - aX - 5;
                            const y = e.clientY - aY - 5;


                            // TODO: Get origin port pin explorer and set x,y to current mouse pos + "isExploring = true"
                            props.originPort.ephemerals.portExplorerAPI?.setExploring(x, y);
                            backend.events.notify(NodeCanvasBackendEventTypes.GRAPH_UPDATE, undefined);
                            e.stopPropagation();
                            e.preventDefault();
                        }
                    }}
                    onClick={e => {
                        ctx.reverseBool("selected")
                    }}
                />
            </svg>
        </StyledPortWireDisplay>
    );
}
