import {NodeSetupInfo} from "../../../NodeSetupInfo";
import {Node} from "../../../../backend/Node";
import {v4} from "uuid";
import {GaugeDisplay} from "./NodeGauge";
import {triton} from "../../../../../triton/Triton";
import {DefaultCharacterSymbols} from "../../../DefaultCharacterSymbols";
import {FC, ReactNode} from "react";
import {Tag} from "../../../../../ardai/components/Tag";
import {
    AirRounded, LastPageRounded,
    PercentRounded,
    PlayCircleFilledWhiteRounded,
    ThermostatRounded,
    VerticalAlignTopRounded
} from "@mui/icons-material";
import {IndicatorContainer} from "../../../components/indicators/IndicatorContainer";
import {IndicatorIcon} from "../../../components/indicators/IndicatorIcon";
import {IndicatorUtils} from "../../../components/indicators/utils/IndicatorUtils";
import {Interpolate} from "../../../../../triton/components/advanced/Interpolate";
import {DescriptiveTypography} from "../../../../../triton/components/typography/DescriptiveTypography";

type NodeCoolerState = {
    // internal status
    enabled: boolean,
    isAllowingMaxCoolingMode: boolean,
    coolingMode: number,
    //  0-1 -> 0%-100%
    requestedPerformance: number,
    performanceQuotaReached: number,
    // fuel
    level: number,
    maxCapacity: number,
    capacity: number,
    // visual config
    enableDisplay: boolean
    // debug state
    isInDebugMode: boolean
}

export const NodeCooler: NodeSetupInfo = {
    label: "NodeCooler",
    classname: "sim.cooler",
    parameterConfig: [],
    factory: parameters => new Node<NodeCoolerState>({
        id: v4(),
        classname: "sim.cooler",
        label: "cooler",
        defInPins: [DefaultCharacterSymbols.clockPinKey, "#t", "cM", "#", "e"],
        defOutPins: ["#"],
        state: {
            // internal status
            enabled: false,
            isAllowingMaxCoolingMode: false,
            requestedPerformance: 0,
            performanceQuotaReached: 0,
            coolingMode: 0,
            // fuel
            level: 0,
            maxCapacity: 1e2,
            capacity: 0,
            // visual config
            enableDisplay: true,
            // debug state
            isInDebugMode: false,
        },
        init: function () {

            this.pins.in("e").attachOnRead(enabled => {
                enabled = Boolean(enabled);
                this.state.update(prevState => {
                    return {
                        enabled,
                        capacity: enabled ? prevState.maxCapacity * prevState.requestedPerformance : 0
                    }
                });
            });

            this.pins.in("cM").config.displayName = "cooling mode";
            this.pins.in("cM").attachOnRead(coolingMode => {
                coolingMode = Number(coolingMode);

                const cur = this.state.state;
                const getNormalModeFuelBufferCapacity = () => cur.maxCapacity * cur.requestedPerformance;

                switch (coolingMode) {
                    // request: deactivate cooler
                    case 0: {
                        this.state.update(prevState => {
                            return {
                                enabled: false,
                                capacity: 0
                            }
                        });
                        break;
                    }
                    // request: activate and operate at preset settings
                    case 1: {
                        this.state.update(prevState => {
                            return {
                                enabled: true,
                                capacity: getNormalModeFuelBufferCapacity()
                            }
                        });
                        break;
                    }
                    // request: activate and operate at max possible power
                    case 2: {
                        this.state.update(prevState => {
                            // const isAllowingMaxCoolingMode = false
                            const isAllowingMaxCoolingMode = true
                            const maxAllowedCapacity = isAllowingMaxCoolingMode ? prevState.maxCapacity : getNormalModeFuelBufferCapacity()

                            return {
                                enabled: true,
                                capacity: maxAllowedCapacity
                            }
                        });
                        break;
                    }
                }

                this.state.update({
                    coolingMode
                });
            });

            this.pins.in("#t").config.displayName = "cooling target";

            this.pins.in(DefaultCharacterSymbols.clockPinKey).attachOnRead(by => {
                const cur = this.state.state;
                const sourcePin = this.pins.in("#t");
                if (!sourcePin.hasInputConnections) return;

                if (!cur.enabled) return;

                const coolingTarget = sourcePin.inputConnections[0].node;
                const fuelUnitToCoolingAmount = .5;

                const tarCur = coolingTarget.state.state;
                const temperatureKey = "temperature";
                const tarTemperature = tarCur[temperatureKey];

                let fuelLevel = cur.level;

                if (cur.isInDebugMode) {
                    fuelLevel = cur.capacity;
                }

                const performanceQuotaReached = fuelLevel === 0 ? 0 : fuelLevel / cur.capacity;

                const curCooling = fuelLevel * fuelUnitToCoolingAmount;
                let newTarTemperature = tarTemperature - curCooling;
                const minAllowedTemperature = -30;
                newTarTemperature = Math.max(minAllowedTemperature, newTarTemperature)

                coolingTarget.state.update({
                    [temperatureKey]: newTarTemperature,
                });

                this.state.update({
                    level: 0,
                    performanceQuotaReached
                });
            });

            // Adjust tank level by provided value 'by'.
            this.pins.in("±").attachOnRead(by => {
                // Numeric safeguard
                by = Number(by ?? 0);

                this.state.update(prevState => ({
                    level: Math.max(0, prevState.level + by)
                }));
            });
        },
        customRenderer: node => {
            const state = node.state;
            const cur = state.state;
            const isDisplayEnabled = cur.enableDisplay ?? false;
            if (!isDisplayEnabled) return undefined;
            return (
                <NodeCoolerDisplay node={node}/>
            );
        }
    })
}

const NodeCoolerDisplay: FC<{
    node: Node<NodeCoolerState>
}> = props => {
    const state = props.node.state;
    const cur = state.state;

    const coolingMode = cur.coolingMode;

    const updateRequestedPerformance = (by: number, delta: boolean = false) => {
        state.update(prevState => ({
            requestedPerformance:
                Math.min(Math.max(delta ? (prevState.requestedPerformance + by) : by, 0), 1)
        }))
    }

    return (
        <div style={{
            padding: "8px 0",
            display: "flex",
            flexDirection: "column",
            gap: 6 // -> becomes a visible gap of 4px, cause containers have 1px box-shadows each
        }}>
            <IndicatorContainer compact columns={1}>
                <GaugeDisplay
                    actual={cur.capacity / cur.maxCapacity * 100}
                    visuals={{
                        showMarkedAreas: false,
                        showThresholdMarkers: false,
                        showValue: true,
                        centralModuleWidth: 25,
                        height: 75
                    }}
                    modules={{
                        enableMarkerModule: false
                    }}
                />
            </IndicatorContainer>

            <IndicatorContainer>

                {/*
                <IndicatorIcon
                    mappingKey={cur.capacity / cur.maxCapacity}
                    colorMapping={new IndicatorUtils.NumericMapping<string>(mapping => mapping
                        .eq(1, "#9148e1")
                        .fallback(0, "rgb(33, 38, 45)")
                    )}
                    iconMapping={new IndicatorUtils.NumericMapping<ReactNode>(mapping => mapping
                        .fallback(0, <AirRounded/>)
                    )}
                />

                <Interpolate value={(cur.capacity / cur.maxCapacity) * 1e2} children={d => (
                    <DescriptiveTypography text={d} style={{
                        fontSize: 12,
                        textAlign: "end"
                    }}/>
                )}/>
                */}

                <IndicatorIcon
                    mappingKey={cur.performanceQuotaReached}
                    colorMapping={new IndicatorUtils.NumericMapping<string>(mapping => mapping
                        .mappingFn(() => cur.performanceQuotaReached < 1 && cur.enabled)(0, "#ffdf60")
                        .mappingFn(() => cur.enabled)(0, "#60ffc7")
                        .fallback(0, "rgb(33, 38, 45)")
                    )}
                    iconMapping={new IndicatorUtils.NumericMapping<ReactNode>(mapping => mapping
                        .fallback(0, <PlayCircleFilledWhiteRounded/>)
                    )}
                />

                <Interpolate value={cur.performanceQuotaReached * 1e2} children={d => (
                    <DescriptiveTypography text={d} style={{
                        fontSize: 12,
                        textAlign: "end"
                    }}/>
                )}/>

                <IndicatorIcon
                    mappingKey={cur.requestedPerformance}
                    colorMapping={new IndicatorUtils.NumericMapping<string>(mapping => mapping
                        .fallback(0, "rgb(33, 38, 45)")
                    )}
                    iconMapping={new IndicatorUtils.NumericMapping<ReactNode>(mapping => mapping
                        .fallback(0, <PercentRounded/>)
                    )}
                />
                <Interpolate value={cur.requestedPerformance * 1e2} children={d => (
                    <DescriptiveTypography text={d} style={{
                        fontSize: 12,
                        textAlign: "end"
                    }}/>
                )}/>

                <IndicatorIcon
                    mappingKey={coolingMode}
                    colorMapping={new IndicatorUtils.NumericMapping<string>(mapping => mapping
                        .eq(2, "#9148e1")
                        .fallback(0, "rgb(33, 38, 45)")
                    )}
                    iconMapping={new IndicatorUtils.NumericMapping<ReactNode>(mapping => mapping
                        .fallback(0, (
                            <LastPageRounded sx={{
                                transform: "rotate(-90deg)"
                            }}/>
                        ))
                    )}
                />
            </IndicatorContainer>

            <IndicatorContainer>
                <Tag tag={"-"} onClick={e => updateRequestedPerformance(-(e.altKey ? 1e-1 : 1e-2), true)}/>
                <Tag tag={"+"} onClick={e => updateRequestedPerformance(e.altKey ? 1e-1 : 1e-2, true)}/>

                <Tag tag={"0"} onClick={() => updateRequestedPerformance(0)}/>
                <Tag tag={
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        width: "100%",
                        height: "100%"
                    }}>
                        <VerticalAlignTopRounded sx={{
                            fontSize: 14,
                            position: "absolute"
                        }}/>
                    </div>
                } onClick={() => updateRequestedPerformance(1)}/>
            </IndicatorContainer>
        </div>
    );
}
