import {createElement, Fragment, ReactNode} from "react";
import {DataRiggingTarget} from "../data/DataRiggingTarget";
import {Color, MeshPhysicalMaterial} from "three";

export function createInlineRiggingTarget(config: Partial<InlineRiggingTargetConfig>): DataRiggingTarget {

    const finalConfig: InlineRiggingTargetConfig = {
        renderer: () => undefined,
        stdMaterial: true,
        color: "white",
        ...config,
    }

    const renderer = () => {

        // const material = new MeshPhysicalMaterial()
        // material.side = 2;
        // material.roughness = .7;
        // material.transmission = .7;
        // material.thickness = 1.5;
        // material.envMapIntensity = 1.5;

        return createElement(
            Fragment,
            {},
            finalConfig.renderer({
                children: (
                    <>
                        { finalConfig.stdMaterial && (
                            <meshPhysicalMaterial
                                side={2}
                                roughness={.07}
                                transmission={.7}
                                thickness={1.5}
                                envMapIntensity={1.5}
                                color={finalConfig.color}
                            />
                        ) }
                    </>
                )
            }),
        )
    }

    return {
        target: renderer,
        targetType: "inline"
    }
}

export type InlineRiggingTargetConfig = {
    stdMaterial: boolean,
    color?: string,
    renderer: (ctx: InlineRiggingTargetRenderContext) => ReactNode
}

export type InlineRiggingTargetRenderContext = {
    children: ReactNode
}
