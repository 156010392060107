export class Stack {
    public data: Array<any> = [];
    size = () => this.data.length;
    clear = () => this.data = [];
    push = (...word: any[]): void => this.data.push(...word) as never;
    pop = (): any => this.data.pop();
    popN = (n: number = 1): any => {
        const data: Array<any> = [];
        for (let i = 0; i < n; i++) {
            data.push(this.pop())
        }
        return data;
    };
    get = (base: number, offset: number = 0): any => this.data[base + offset];
    getN = (base: number, offset: number = 0, n: number = 1): any[] => {
        const slice: Array<any> = [];
        for (let i = 0; i < n; i++) {
            slice.push(this.data[base + offset + i]);
        }
        return slice;
    };
    storeAt = (idx: number, words: any[]) => {
        let i = idx;
        words.forEach((word: any) => {
            this.data[i++] = word;
        });
    };
    shrink = (newSize: number = 0) => {
        this.data = this.data.slice(0, newSize);
    }
}

export class Instruction {
    constructor(
        public readonly opcode: string,
        public readonly n: number = 0,
        public readonly d: number = 0,
        public readonly r: RegisterName = "LB"
    ) {}
}

export class Program {
    constructor(
        public readonly instructions: Array<Instruction> = []
    ) {}
}

export class InstructionImpl {
    constructor(
        public readonly opcode: string,
        public readonly executor: (
            machine: StackMachine,
            ins: Instruction,
        ) => void
    ) {}
}

export class Register {
    private value: number = 0;
    public dirtyFlag: boolean = false;

    public read() {
        return this.value;
    }

    public write(value: number) {
        this.value = value;
        this.dirtyFlag = true;
    }
}

export type RegisterName = "LB" | "ST" | "CP";

// noinspection SpellCheckingInspection
export class StackMachine {
    public readonly stack = new Stack();
    private readonly instructions: Map<string, InstructionImpl> = new Map;
    public program: Program = new Program();
    public flagHalted: boolean = false;
    public instructionCounter: number = 0;
    public currentInstruction?: Instruction;
    public currentInstructionCodePoint?: number;
    private readonly registers: Map<RegisterName, Register> = new Map([
        ["LB", new Register()],
        ["ST", new Register()],
        ["CP", new Register()]
    ]);

    public createInstruction(ins: InstructionImpl): this {
        this.instructions.set(ins.opcode, ins);
        return this;
    }

    public register(name: RegisterName): Register {
        return this.registers.get(name)!;
    }

    private getInstructionImpl(opcode: string): InstructionImpl {
        return this.instructions.get(opcode)!;
    }

    public createSnapshot(): string {
        let coreLog = "";
        const println = (line: string) => coreLog += line + "\n";
        println("Registers:");
        Array.from(this.registers.entries()).forEach(([name, reg]) => {
            println(`  ${name}: ${reg.read()}`);
        })
        println("Stack:");
        this.stack.data.forEach((word, idx) => {
            println(`${String(idx).padStart(3, " ")}: ${word}`);
        });
        return coreLog;
    }

    public loadProgram(program: Program): this {
        this.program = program;
        return this;
    }

    public get CP(): Register {
        return this.register("CP");
    }

    public runNextInstruction() {
        if (this.flagHalted) return;
        try {
            const codePoint = this.CP.read();
            this.currentInstructionCodePoint = codePoint;
            const nextIns = this.program.instructions[codePoint];
            this.currentInstruction = nextIns;
            const impl = this.getInstructionImpl(nextIns.opcode);
            impl.executor(this, nextIns);

            if (!this.CP.dirtyFlag) {
                this.CP.write(this.CP.read() + 1);
            }

            this.instructionCounter++;

            this.registers.forEach(reg => {
                reg.dirtyFlag = false;
            })


        } catch (e) {
            console.error("Program execution failed:\n", e);
            console.error(this.createSnapshot());
        }
    }

    public run(): void {
        try {
            while (!this.flagHalted) {
                this.runNextInstruction();
            }
        } catch (e) {
            console.error("Program execution failed:\n", e);
            console.error(this.createSnapshot());
        }
    }
}
