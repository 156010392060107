import {Node} from "../../../backend/Node";
import {NodeSetupInfo} from "../../NodeSetupInfo";
import {v4} from "uuid";
import {PIDController} from "../../../../ship/test2/math/PIDController";
import {DefaultCharacterSymbols} from "../../DefaultCharacterSymbols";
import {Std} from "../../../../../Std";
import also = Std.also;

export const NodePIDController: NodeSetupInfo = {
    classname: "tuning.pid-controller",
    label: "NodePIDController",
    parameterConfig: [],
    factory(parameters: Map<string, any>): Node {
        return new NodePIDControllerClass;
    }
}

export class NodePIDControllerClass extends Node {

    constructor() {
        super({
            id: v4(),
            label: "PID",
            classname: "tuning.pid-controller",
            defInPins: [DefaultCharacterSymbols.clockPinKey]
        });
    }

    private pid = also(new PIDController(), pid => {
        pid.setGains(1, .5, .25);
        pid.setOutputBounds(-1, 1)
        pid.setTarget(0);
        pid.tolerance = .25;
    });

    output = this.pins.out("out");

    error = this.pins.out("err");

    feedback = this.pins.in("feed").setDisplayName("feedback");

    target = this.pins.in("tar").attachOnRead(target => {
        target = Number(target);
        this.pid.setTarget(target);
    });

    gainP = this.pins.in("p").attachOnRead(gain => {
        gain = Number(gain) ?? 1;
        this.pid.setP(gain);
    });

    gainI = this.pins.in("i").attachOnRead(gain => {
        gain = Number(gain) ?? 1;
        this.pid.setI(gain);
    });

    gainD = this.pins.in("d").attachOnRead(gain => {
        gain = Number(gain) ?? 1;
        this.pid.setD(gain);
    });

    outputBoundsMin = also(this.pins.in("oMin"), pin => {
        pin.config.displayName = "output bounds min";
        pin.attachOnRead(min => {
            min = Number(min);
            this.pid.setOutputBoundsMin(min);
        });
    });

    outputBoundsMax = also(this.pins.in("oMax"), pin => {
        pin.config.displayName = "output bounds max";
        pin.attachOnRead(max => {
            max = Number(max);
            this.pid.setOutputBoundsMax(max);
        });
    });

    tolerance = this.pins.in("tol").setDisplayName("tolerance").attachOnRead(tolerance => {
        tolerance = Number(tolerance);
        this.pid.tolerance = tolerance;
    });

    clock = this.pins.helpers.clock().attachOnRead(() => {
        const feedback = Number(this.feedback.lastReadState) ?? 0;
        const newTarget = this.pid.run(feedback);
        this.output.write(newTarget);
        this.error.write(this.pid.error);
    });

}
